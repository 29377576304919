import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Link, useSearchParams } from "react-router-dom";
import { trackEvent } from "../custom/analytics";
import { LeftArrowSVG, PlusSVG, RightArrowSVG } from "../custom/svg";
import { CircularProgress } from "@mui/material";
import { compareLink, preIngredients } from "../custom/functions";
import { Helmet } from "react-helmet-async";

// CSS FOLD
const StyledLink = styled(Link)`
  text-decoration: none; /* Remove underline */
  color: inherit; /* Ensure text color is inherited */
`;
const ProductDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  gap: 50px;
  position: static;
  padding: 10px 0px 30px 0px;
  max-width: 1600px;

  @media (max-width: 760px) {
    flex-direction: column;
    // padding: 0px 0px 30px 0px;
    padding: ${(props) =>
      props.backActive ? "0px 0px 30px 0px" : "10px 0px 30px 0px"};
    align-items: center;
    gap: 25px;
  }
`;
const BackRoundedSquare = styled.div.attrs((props) => ({
  color: props.color,
}))`
  display: block;
  text-decoration: none;
  color: black;
  width: 40%;
  background-color: ${(props) => props.color};
  border-radius: 15px;
  position: static;
  //top: 15px;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  @media (max-width: 760px) {
    flex-direction: column;
    width: 65%;
  }
`;
const FrontRoundedSquare = styled.div`
  //width: 96%;
  background-color: white;
  border-radius: 10px;
  position: static;
  margin: 5px;
  padding: 5px;
`;
const ItemDiv = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ItemLogo = styled.img`
  height: 85%;
  position: static;
`;
const Info = styled.div`
  width: 45%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  @media (max-width: 760px) {
    width: 90%;
    margin-top: 0px;
  }
`;
const ProductName = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  margin: 0 auto;
`;
const TypeSubTitle = styled.p`
  color: gray;
  font-size: 14px;
  margin: 5px 0px 5px 0px;
  font-family: San Francisco Regular;
`;
const Disclaimer = styled.p`
  max-width: 350px;
  color: gray;
  //letter-spacing: 0.7px;
  font-size: 10px;
  font-family: San Francisco Regular;
`;
const PriceDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`;
const Price = styled.p`
  color: black;
  //letter-spacing: 0.7px;
  font-size: 18px;
  font-weight: bold;
  margin: 0px 0px 0px 0px;
  font-family: San Francisco Bold;
`;
const ShopNow = styled.button.attrs((props) => ({
  color: props.color,
}))`
  all: unset;
  display: block;
  text-decoration: none;
  background-color: gray;
  width: 100px;
  height: 40px;
  background-color: ${(props) => props.color};
  border-radius: 10px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  margin: 0 auto;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;
const ShopText = styled.p.attrs((props) => ({
  color: props.color,
}))`
  font-size: 1rem;
  font-family: San Francisco Bold;
  color: ${(props) => props.color};
  margin: auto;
  line-height: 40px;
  position: static;
`;
const Table = styled.table`
  border-collapse: seperate;
  position: static;
  width: 80%;
  padding: 0px 0px 0px 0px;
  margin-left: auto;
  margin-right: auto;
  font-family: San Francisco Regular;

  & span {
    float: left;
    margin: 0.5rem;
  }

  & th,
  td {
    border-style: dashed;
    border: 1px solid #ccc;
  }

  @media (max-width: 760px) {
    width: 90%;
  }
`;
const SizeDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 10px;
  // padding-bottom: 10px;
  width: 70%;

  @media (max-width: 760px) {
    width: 90%;
  }
`;
const FlavorButton = styled.button`
  all: unset;
  display: block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 10px;
  padding: 2px;
  border: none;
  background: #efefef;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  transition-duration: 0.25s;
  // box-shadow: ${(props) => props.active && "0px 0px 5px black"};

  &:hover {
    box-shadow: 0px 0px 5px black;
  }
`;
const FlavorText = styled.p`
  font-size: 0.9rem;
  margin: 5px;
  position: static;
  line-height: 20px;
  color: black;
  font-family: San Francisco Bold;
`;
const TabBar = styled.div`
  display: block;
  height: 3px;
  width: 70%;
  border-radius: 50px;
  background-color: #efefef;
  margin-top: 10px;
  margin-bottom: 10px;

  @media (max-width: 760px) {
    width: 90%;
  }
`;
const ArrowDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const RankButton = styled.button`
  all: unset;
  // text-decoration: underline;
  // color: gray;
  // background: gray;
  display: flex;
  align-items: center;

  &:active {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
  }
`;
const BackDiv = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  line-height: 14px;
  font-family: San Francisco Regular;
  padding-bottom: 10px;
  // background: gray;

  @media (max-width: 760px) {
    width: 90%;
    height: 50px;
    line-height: 12px;
    padding-bottom: 0px;
  }
`;
const BackButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 5px;

  &:active {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
  }
`;
const BackText = styled.p`
  font-size: 14px;
  color: black;
  // margin: 0px;

  @media (max-width: 760px) {
    font-size: 12px;
  }
`;
const TableIngredient = styled.td`
  width: 65%;
`;

const TableValue = styled.td`
  width: 35%;
`;
const TableDefinition = styled.p`
  color: gray;
  font-size: 14px;
  margin: 10px 5px 10px 5px;
  font-family: San Francisco Regular;
`;
const UnderlinedSpan = styled.span`
  text-decoration: underline; /* Add underline to the span */
  color: inherit; /* Ensure text color is inherited */
  cursor: pointer;
`;
const TableButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  //   background: yellow;

  @media (max-width: 760px) {
    width: 85%;
  }
`;
const TableButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  &:hover {
    text-decoration: underline;
  }
`;
const CompareButton = styled(Link)`
  all: unset;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: black;
  // text-decoration: underline;

  &:hover {
    cursor: pointer;
  }

  &:active {
    text-decoration: underline;
  }
`;

const NutritionTable = (props) => {
  const [selectedIngredient, setSelectedIngredient] = useState(null);

  const handleIngredientClick = (ingredient) => {
    if (ingredient === selectedIngredient) {
      return setSelectedIngredient(null);
    }
    setSelectedIngredient(ingredient);
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>
            <span>Nutrition: </span>
          </th>
          <th>
            <span>Amount: </span>
          </th>
          {/* <th>
              <span>Category Rank: </span>
            </th> */}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span>Servings</span>
          </td>
          <td>
            <span>{props.servings}</span>
          </td>
        </tr>
        <tr>
          <TableIngredient>
            <UnderlinedSpan
              onClick={() => handleIngredientClick("betaalanine")}
            >
              Beta Alanine
            </UnderlinedSpan>
          </TableIngredient>
          <TableValue>
            <span>{props.servingSize}mg</span>
          </TableValue>
        </tr>
        {selectedIngredient && selectedIngredient === "betaalanine" && (
          <tr>
            <td colSpan="2" style={{ border: "none" }}>
              <TableDefinition>
                {
                  preIngredients.find((item) => item.value === "betaalanine")
                    .definition
                }
              </TableDefinition>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

const BetaProduct = (props) => {
  let sign = "$";

  const { brand, type, flavor, servings, region } = useParams();
  const [productGroup, setProductGroup] = useState({
    category: null,
    brand: null,
    subtitle: null,
    type: null,
    region: null,
  });
  const [productLoading, setProductLoading] = useState(true);
  const [productData, setProductData] = useState([]);

  if (region === "GB") {
    sign = "£";
  }

  useEffect(() => {
    // if (searchParams.get("region")) {
    //   props.setUserCountry(searchParams.get("region"));
    // }
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (brand && type && region) {
      setProductGroup({
        category: "betaalanine",
        brand: brand,
        subtitle: null,
        type: type,
        region: region,
      });
    }
  }, [brand, type, region]);

  const constructQueryParams = (params) => {
    if (typeof params !== "object" || Array.isArray(params)) {
      throw new Error("Params should be an object with key-value pairs.");
    }

    let query = "/api/product-group?";
    for (const [key, value] of Object.entries(params)) {
      // console.log(`Processing key: ${key}, value: ${value}`); // Debugging line
      if (value !== null && value !== "") {
        query += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
      }
    }

    return query.endsWith("&") ? query.slice(0, -1) : query;
  };

  useEffect(() => {
    if (
      productGroup.category &&
      productGroup.brand &&
      productGroup.type &&
      productGroup.region
    ) {
      setProductLoading(true);
      fetch(constructQueryParams(productGroup))
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setProductData(data);
          setProductLoading(false);
        })
        .catch((error) => {
          console.error("Error caught:", error);
          setProductLoading(false);
        });
    }
  }, [productGroup]);

  if (productLoading) {
    return (
      <center style={{ paddingTop: "150px" }}>
        <CircularProgress color="inherit" />
      </center>
    );
  }

  const prod = productData.find(
    (obj) =>
      obj.brand === brand &&
      obj.type === type &&
      (obj.flavor === null ? "null" : obj.flavor) === flavor &&
      obj.servings === parseInt(servings)
  );

  if (!prod) {
    return (
      <center style={{ paddingTop: "150px" }}>
        <CircularProgress color="inherit" />
      </center>
    );
  }

  // const flavors = productData.filter(
  //   (obj) =>
  //     obj.brand === brand &&
  //     obj.type === type &&
  //     obj.servings === servings &&
  //     obj.stock === true
  // );

  const sizes = productData.filter(
    (obj) =>
      obj.brand === brand &&
      obj.type === type &&
      (obj.flavor === null ? "null" : obj.flavor) === flavor &&
      obj.stock === true
  );

  let backColor = "#efefef";
  let textColor = "black";
  const rank =
    props.betaFilter.findIndex(
      (obj) => obj.brand === prod.brand && obj.type === prod.type
    ) + 1;

  if (rank === 1) {
    backColor = "#d4af37";
    textColor = "white";
  } else if (rank === 2) {
    backColor = "#C0C0C0";
    textColor = "white";
  } else if (rank === 3) {
    backColor = "#CD7F32";
    textColor = "white";
  } else {
    backColor = "#efefef";
    textColor = "black";
  }
  // green: #00a650
  // yreen: #cadb2a
  // red: #ed1b24

  const getStyle = (bool) => {
    return bool
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyle = (bool) => {
    return bool
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const getPrev = () => {
    if (rank === 0 || rank === 1) {
      return null;
    }

    const link =
      "/betaalanine/" +
      props.betaFilter[rank - 2].brand +
      "/" +
      props.betaFilter[rank - 2].type +
      "/" +
      props.betaFilter[rank - 2].flavor +
      "/" +
      props.betaFilter[rank - 2].servings +
      "/" +
      props.betaFilter[rank - 2].region;

    return link;
  };

  const getNext = () => {
    if (rank === 0 || rank === props.betaFilter.length) {
      return null;
    }

    const link =
      "/betaalanine/" +
      props.betaFilter[rank].brand +
      "/" +
      props.betaFilter[rank].type +
      "/" +
      props.betaFilter[rank].flavor +
      "/" +
      props.betaFilter[rank].servings +
      "/" +
      props.betaFilter[rank].region;

    return link;
  };

  return (
    <center>
      <Helmet>
        <title>{prod.brand + " - Beta Alanine " + (prod.type === "powder" ? "Powder" : "Capsules")}</title>
        <meta
          name="description"
          content={
            "View or compare " +
            prod.brand +
            "'s Beta Alanine " +
            (prod.type === "powder" ? "Powder" : "Capsules") +
            " to it's competitors."
          }
        />
        <meta
          name="keywords"
          content="best beta alanine, beta alanine rankings, beta alanine supplement, beta alanine value, top beta alanine, effective beta alanine, affordable beta alanine, workout supplements, beta alanine comparison, ingredient-based beta alanine, ingredient value beta alanine, beta alanine definition"
        />
        <meta property="og:title" content={prod.brand + " - Beta Alanine " + (prod.type === "powder" ? "Powder" : "Capsules")} />
        <meta
          property="og:description"
          content={
            "View or compare " +
            prod.brand +
            "'s Beta Alanine " +
            (prod.type === "powder" ? "Powder" : "Capsules") +
            " to it's competitors."
          }
        />
      </Helmet>
      {rank > 0 && (
        <BackDiv>
          <StyledLink to={props.betaFilterLink} method="get">
            <BackButton type="submit">
              <LeftArrowSVG />
              <BackText>Full Ranking Page</BackText>
            </BackButton>
          </StyledLink>
          {/* <BackText style={{color:"gray"}}>{rank} Place</BackText> */}
        </BackDiv>
      )}
      <ProductDiv backActive={rank > 0}>
        <BackRoundedSquare color={backColor}>
          <FrontRoundedSquare>
            <ItemDiv>
              <ItemLogo src={prod.imglink} />
            </ItemDiv>
          </FrontRoundedSquare>
        </BackRoundedSquare>
        <Info>
          {rank > 0 ? (
            <ArrowDiv>
              <StyledLink
                onClick={() => setProductLoading(true)}
                to={getPrev()}
                method="get"
              >
                <RankButton type="submit">
                  <LeftArrowSVG color={rank === 1 ? "white" : "black"} />
                </RankButton>
              </StyledLink>
              <div>
                <ProductName>{prod.brand}</ProductName>
                <TypeSubTitle>
                  Beta Alanine {prod.type === "powder" ? "Powder" : "Capsules"}
                </TypeSubTitle>
              </div>
              <StyledLink
                onClick={() => setProductLoading(true)}
                to={getNext()}
                method="get"
              >
                <RankButton type="submit">
                  <RightArrowSVG
                    color={rank === props.betaFilter.length ? "white" : "black"}
                  />
                </RankButton>
              </StyledLink>
            </ArrowDiv>
          ) : (
            <div>
              <ProductName>{prod.brand}</ProductName>
              <TypeSubTitle>
                Beta Alanine {prod.type === "powder" ? "Powder" : "Capsules"}
              </TypeSubTitle>
            </div>
          )}
          <Price>
            {sign + prod.price}
            {!prod.shoplink.includes("amzn") &&
              !prod.shoplink.includes("lvnta") &&
              "*"}
          </Price>
          <SizeDiv>
            {sizes.map(
              (item) =>
                item && (
                  <StyledLink
                    to={
                      "/betaalanine/" +
                      item.brand +
                      "/" +
                      item.type +
                      "/" +
                      item.flavor +
                      "/" +
                      item.servings +
                      "/" +
                      item.region
                    }
                    method="get"
                    key={item.brand + " " + item.flavor + " " + item.servings}
                  >
                    <FlavorButton
                      type="submit"
                      style={getStyle(item.servings === prod.servings)}
                    >
                      <FlavorText
                        style={getTextStyle(item.servings === prod.servings)}
                      >
                        {item.servings} Servings
                      </FlavorText>
                    </FlavorButton>
                  </StyledLink>
                )
            )}
          </SizeDiv>
          {/* <SubTitle>
            Ranked #{rank} in Creatine evaluated by Servings and Price.
          </SubTitle> */}
          <TabBar />
          <a
            style={{ all: "unset" }}
            href={prod.shoplink}
            method="get"
            target="_blank"
            rel="noreferrer"
          >
            <ShopNow
              color={backColor}
              onClick={() => {
                trackEvent("ShopNow", "Shopped: Beta Alanine");
              }}
            >
              <ShopText color={textColor}>Shop Now</ShopText>
            </ShopNow>
          </a>
          <Disclaimer>
            {!prod.shoplink.includes("amzn") &&
              !prod.shoplink.includes("lvnta") &&
              "*If price isn't accurate at checkout, use code SUPPHEAD."}
            {!prod.shoplink.includes("amzn") &&
              !prod.shoplink.includes("lvnta") && <br />}
            These rankings are based on Nutritional statistics alone. <br />{" "}
            They have no consideration for flavor of product.
          </Disclaimer>
          <div style={{ padding: "10px 0px", width: "100%" }}>
            <TableButtonDiv>
              <TableButton>
                <PlusSVG />
                <CompareButton to={compareLink(prod)}>Compare</CompareButton>
              </TableButton>
            </TableButtonDiv>
            <NutritionTable
              servingSize={prod.servingsize}
              servings={prod.servings}
            />
          </div>
        </Info>
      </ProductDiv>
    </center>
  );
};

export default BetaProduct;
