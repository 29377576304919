import React, { useState, useEffect, useRef } from "react";
import BrandListCompact from "../components/BrandListCompact";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { RightArrowSVG, LockSVG, IngredientEyeSVG } from "../custom/svg";
import { CircularProgress } from "@mui/material";
import { ReactTyped } from "react-typed";
import mainlogo from "../images/suppheadgradient.png";
import { GradientAnimation } from "../custom/animations";
import {
  TrophySVG,
  ListSVG,
  CloseSVG,
  DollarSVG,
  FilterSVG,
  CartSVG,
} from "../custom/svg";
import { trackEvent } from "../custom/analytics";
import podiumGlyph from "../images/podiumglyph.png";
import {
  creatineIngredients,
  electrolyteIngredients,
  massIngredients,
  preIngredients,
  proteinIngredients,
} from "../custom/functions";
import {
  titleMakerPW,
  subMakerElectrolytes,
  subMakerPW,
  subMakerProtein,
  tieBreaker,
  searchPageGen,
} from "../custom/functions";
import PopUp from "../components/PopUp";
import { Helmet } from "react-helmet-async";
import BottlePopUp from "../components/AdPopUp";
import AdPopUp from "../components/AdPopUp";

const HomeLogo = styled.img`
  width: 25%;
  position: static;
  margin-top: 20px;
  // padding: 5px;
  transition-duration: 0.25s;
  background: ${(props) =>
    props.logo
      ? "linear-gradient(135deg, black 0%, black 45%, white 50%, black 55%, black 100%)"
      : "black"};
  background-size: 400% 400%;
  // border-style: solid;
  // border-color: white;
  // border-width: unset;
  animation: ${GradientAnimation} 10s ease infinite;
  width: 40%;
  max-width: 450px;

  @media (max-width: 760px) {
    width: 70%;
    max-width: 400px;
  }
`;

// const LoadingLogo = styled.img`
//   height: 100px;
//   width: 100px;
//   background: linear-gradient(
//     135deg,
//     black 0%,
//     black 45%,
//     white 50%,
//     black 55%,
//     black 100%
//   );
//   animation: ${GradientAnimation} 10s ease infinite;
//   background-size: 400% 400%;
// `;

const Subtitle = styled.p`
  width: 50%;
  font-family: San Francisco Regular;
  color: #363636;
  //letter-spacing: 0.7px;
  position: static;
  font-size: 16px;
  transition-duration: 0.25s;
  max-width: 900px;
  margin: 5px;
  margin-bottom: 25px;

  @media (max-width: 760px) {
    font-size: 14px;
    width: 80%;
  }
`;

const PodiumDivMobile = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: static;
  width: 70%;
  max-width: 700px;
  gap: 10px;
  display: flex;

  // padding-bottom: 40px;

  @media (max-width: 760px) {
    display: flex;
    width: 95%;
    // padding-bottom: 30px;
  }
`;

const SearchDiv = styled.form`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
`;

const CharCountDiv = styled.div`
  width: 70%;
  // background: gray;
  margin-bottom: 5px;
  max-width: 550px;

  @media (max-width: 760px) {
    width: 85%;
  }
`;

const CharCount = styled.p`
  text-align: left;
  margin: 5px 0px;
  font-size: 12px;
  font-family: San Francisco Regular;
  color: gray;

  &.invalid {
    color: red;
  }
`;

const Input = styled.input`
  box-sizing: border-box;
  height: 45px;
  padding: 10px 10px;
  width: 50%;
  max-width: 500px;
  font-size: 16px;
  font-family: San Francisco Regular;
  border: 2px solid black;
  border-radius: 10px;
  -webkit-appearance: none;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  &:focus {
    outline: none;
    border-color: #d4af37;
  }

  @media (max-width: 760px) {
    width: 70%;
  }
`;

const SearchButton = styled.button`
  all: unset;
  display: flex;
  box-sizing: border-box;
  padding: auto;
  // border-focus: #d4af37;
  text-decoration: none;
  font-family: San Francisco Bold;
  width: 60px;
  height: 45px;
  background-color: #efefef;
  border-radius: 10px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;

const ResponseDiv = styled.div`
  flex-direction: column;
  justify-content: left;
  align-items: left;
  position: static;
  width: 70%;
  max-width: 700px;
  display: flex;
  // background: black;

  @media (max-width: 760px) {
    width: 100%;
    max-width: 700px;
  }
`;

const ResponseTitle = styled.p`
  display: block;
  height: auto;
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  position: static;
  text-align: left;
  padding-left: 40px;
  margin: 0px;

  @media (max-width: 760px) {
    font-size: 1.3rem;
    padding-left: 30px;
  }
`;
const SvgWrapper = styled.span`
  margin-right: 5px;
  margin-bottom: 3px;
  display: inline-flex;
  vertical-align: middle;

  @media (max-width: 760px) {
    margin-bottom: 2px;
  }
`;

const ResponseSubtitle = styled.p`
  font-size: 1rem;
  font-family: San Francisco Regular;
  color: gray;
  text-align: left;
  padding-left: 40px;
  padding-right: 40px;
  margin: 0px;
  padding-top: 5px;
  padding-bottom: 20px;
  white-space: pre-line;
  cursor: pointer;

  @media (max-width: 760px) {
    padding-left: 30px;
    font-size: 0.9rem;
  }

  @media (min-width: 760px) {
    &:hover {
      text-decoration: ${(props) => (props.noUnderline ? "none" : "underline")};
    }
  }

  &:active {
    text-decoration: ${(props) => (props.noUnderline ? "none" : "underline")};
  }
`;

const LoadingResponseDiv = styled.div`
  padding-top: 5px;
  padding-bottom: 20px;
  margin: 0px;
  height: 2.4rem;
  display: block;

  @media (max-width: 760px) {
    height: 2.2rem;
  }
`;

const MaintenanceDiv = styled.div`
  padding: 20px 0px;
`;

const MaintenanceTitle = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  position: static;
  text-align: center;
  margin: 0px;

  @media (max-width: 760px) {
    font-size: 1.3rem;
  }
`;

const MaintenanceSubTitle = styled.p`
  font-size: 1rem;
  font-family: San Francisco Regular;
  color: gray;
  text-align: center;
  margin: 0px;
  padding-top: 5px;
  padding-bottom: 20px;
  white-space: pre-line;

  @media (max-width: 760px) {
    font-size: 0.9rem;
  }
`;

const LoadingDiv = styled.div`
  margin-bottom: 50px;
  margin-top: 60px;
`;

const SuggestionDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 15px;
  cursor: grab;
  user-select: none; // Prevent text selection during drag

  &:active {
    cursor: grabbing;
  }

  overflow-x: auto; // Changed from 'scroll' to 'auto'

  @media (min-width: 760px) {
    width: 70%;
    max-width: 700px;
  }

  // Hiding the scrollbar for WebKit browsers
  &::-webkit-scrollbar {
    display: none;
  }

  // For Firefox 64+
  scrollbar-width: none; // Hides the scrollbar in Firefox

  // For Internet Explorer and Edge
  -ms-overflow-style: none; // Hides the scrollbar in IE and Edge
`;
const Suggestion = styled.button`
  all: unset;
  display: block;
  background: #efefef;
  border-radius: 7px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  &:active {
    cursor: grabbing;
  }

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;

const SuggestionText = styled.div`
  color: black;
  font-family: San Francisco Bold;
  font-size: 1rem;
  position: static;
  text-align: center;
  margin: 5px;
  white-space: nowrap;

  @media (max-width: 760px) {
    font-size: 0.9rem;
  }
`;

const ListRoundedSquare = styled(Link)`
  display: block;
  text-decoration: none;
  color: black;
  width: 95%;
  height: 100px;
  min-height: 75px;
  max-height: 100px;
  border-radius: 15px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  background: ${(props) =>
    props.color !== "#efefef"
      ? "linear-gradient(135deg, " +
        props.color +
        " 0%, " +
        props.color +
        " 45%, white 50%, " +
        props.color +
        " 55%, " +
        props.color +
        " 100%)"
      : "#efefef"};
  background-size: 400% 400%;
  animation: ${GradientAnimation} 10s ease infinite;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 10px gray;
    }
  }

  @media (max-width: 760px) {
    flex-direction: column;
    width: 95%;
  }
`;
const FrontRoundedSquare = styled.div`
  height: 80%;
  background-color: white;
  border-radius: 10px;
  position: static;
  margin: 5px;
  padding: 5px;z
`;
const ItemLoadingDiv = styled.div`
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  margin: auto;
  margin-left: 25px;

  @media (max-width: 1000px) {
    margin-left: 20px;
    margin-right: 9px;
  }
`;
const ListContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: static;
  height: 100%;
  gap: 10px;
  // padding: 0px 0px 0px 0px;
`;
const RankImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: static;
  height: 100%;
  gap: 10px;
`;
const ListTitleDiv = styled.div`
  height: auto;
  width: 80%;
`;
const ListTitle = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.25rem;
  text-align: left;
  padding-left: 10px;
  margin-top: 0px;
  margin-bottom: 5px;
`;
const ListSubtitle = styled.p`
  font-family: San Francisco Regular;
  font-size: 0.9rem;
  text-align: left;
  color: gray;
  padding-left: 10px;
  margin-top: 0px;
  margin-bottom: 3px;
`;
const ListImageDiv = styled.div`
  height: 80%;
  aspect-ratio: 1 / 1;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
`;
const ListImage = styled.img`
  height: 100%;
`;
const ListItemBar = styled.div`
  display: block;
  width: 2px;
  border-radius: 50px;
  background-color: #d4af37;
  // margin-right: 5px;
  height: 72px;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background-color: ${(props) => props.color};
  // margin-top: 30px;

  @media (max-width: 1000px) {
    flex-direction: column;
    padding-bottom: 20px;
  }
`;
const TransparencySection = styled.div`
  display: block;
  width: 30%;

  @media (max-width: 1000px) {
    margin: 0px 10% 0px 10%;
    width: 90%;
  }
`;
const TransparencyItemListDiv = styled.div`
  display: flex;
  font-family: San Francisco Regular;
  flex-direction: column;
  padding-left: 10px;
  padding-bottom: 70px;

  @media (max-width: 1000px) {
    width: 70%;
    padding-bottom: 20px;
  }
`;
const TransparencyItemDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 35px;
`;
const TransparencyTitle = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  transition-duration: 0.25s;
  padding-top: 70px;
  // padding-bottom: 0.3em;

  @media (max-width: 1000px) {
    padding-top: 50px;
    font-size: 1.3rem;
  }
`;
const TailoredTitle = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  transition-duration: 0.25s;
  padding-top: 70px;

  @media (max-width: 1000px) {
    padding-top: 30px;
    font-size: 1.3rem;
  }
`;
const ExampleTitle = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  transition-duration: 0.25s;
  // margin: auto;
  margin: auto;
  padding-bottom: 20px;

  @media (max-width: 1000px) {
    padding-top: 10px;
    font-size: 1.3rem;
  }
`;
const MacbookDiv = styled.div`
  display: block;
  width: 25%;
  max-width: 350px;

  margin-top: auto;
  margin-left: 30px;
  margin-bottom: auto;

  @media (max-width: 1000px) {
    width: 70%;
    margin: auto;
    padding: 20px 0px;
  }
`;
const IphoneDiv = styled.div`
  display: block;
  width: 30%;
  max-width: 400px;
  height: auto;
  margin-right: 40px;
  display: flex; // makes image line up with tailored height
  align-items: flex-end;

  @media (max-width: 1000px) {
    width: 85%;
    margin: auto;
    padding-top: 40px;
  }
`;
const ExampleDiv = styled.div`
  display: block;
  width: 30%;
  max-width: 400px;
  // height: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 40px;
  // margin-bottom: 40px;
  // display: flex; // makes image line up with tailored height
  // align-items: center;

  @media (max-width: 1000px) {
    width: 85%;
    margin: auto;
    // padding-top: 40px;
  }
`;
const Render = styled.img`
  width: 100%;
`;
const TailoredSection = styled.div`
  display: block;
  width: 25%;

  @media (max-width: 1000px) {
    margin: 0% 10% 0px 10%;
    width: 90%;
  }
`;
const ExampleSection = styled.div`
  display: block;
  width: 25%;
  min-width: 300px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 40px 0px;

  @media (max-width: 1000px) {
    margin: 0% 10% 0px 10%;
    width: 90%;
  }
`;
const AboutSubtitle = styled.div`
  font-size: 1rem;
  font-family: San Francisco Regular;

  @media (max-width: 1000px) {
    font-size: 0.9rem;
  }
`;
const ExampleSubtitle = styled.div`
  font-size: 1rem;
  font-family: San Francisco Regular;
  color: gray;
  // margin-bottom: 30px;

  @media (max-width: 1000px) {
    font-size: 0.9rem;
    margin-right: 20px;
    // margin-bottom: 30px;
  }
`;

const LoginSubtitle = styled(Link)`
  display: block;
  font-family: San Francisco Regular;
  font-size: 14px;
  padding-top: 12px;
  color: gray;
  text-decoration: underline;
  cursor: pointer;
`;
const TabBar = styled.div`
  display: block;
  height: 3px;
  width: 66.5%;
  max-width: 665px;
  border-radius: 50px;
  background-color: #efefef;
  margin-top: 20px;
  margin-bottom: 25px;

  @media (max-width: 1000px) {
    width: 90.25%;
  }
`;
const LastTabBar = styled.div`
  display: block;
  height: 3px;
  width: 66.5%;
  max-width: 665px;
  border-radius: 50px;
  background-color: #efefef;
  margin-top: 30px;
  margin-bottom: 0px;

  @media (max-width: 1000px) {
    width: 90.25%;
    margin-bottom: 30px;
  }
`;
const FilterButtonDiv = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: static;
  padding-top: 20px;

  @media (max-width: 900px) {
    width: 90%;
  }
`;
const ShowMoreButton = styled(Link)`
  text-decoration: none;
  width: 80px;
  height: 40px;
  background-color: #efefef;
  border-radius: 10px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0px 0px 5px black;
  }

  @media (max-width: 760px) {
    &:hover {
      box-shadow: none;
    }
  }
`;
const PodiumGlyphImg = styled.img`
  width: 40px;
  position: static;
`;

function responseMaker(filter, category, patent) {
  if (category === "proteinpowder") {
    if (filter === "all") {
      return "All Protein Powder";
    }
    if (filter === "isolate") {
      return "Whey Isolate Protein Powder";
    }
    if (filter === "concentrate") {
      return "Whey Concentrate Protein Powder";
    }
    if (filter === "clear") {
      return "Clear Whey Isolate Protein Powder";
    }
    if (filter === "vegan") {
      return "Vegan Protein Powder";
    }

    return "All Protein Powder";
  }

  if (category === "creatine") {
    let title = "Creatine";

    if (filter === "all" && patent !== "true") {
      return "All Creatine";
    }

    if (filter === "monohydrate") {
      title = "Creatine Monohydrate";
    }
    if (filter === "micronized") {
      title = "Micronized Creatine Monohydrate";
    }
    if (filter === "hcl") {
      title = "Creatine HCl";
    }

    if (patent === "true") {
      title = "Patented " + title;
    }

    return title;
  }

  if (category === "electrolyte") {
    if (filter === "powder") {
      return "Electrolyte Powder";
    }
    if (filter === "capsule") {
      return "Electrolyte Capsules";
    }
    if (filter === "tablet") {
      return "Electrolyte Tablets";
    }
    if (filter === "packet") {
      return "Electrolyte Packets";
    }
    if (filter === "all") {
      return "All Electrolytes";
    }
    return "All Electrolytes";
  }

  if (category === "proteinbar") {
    if (filter === "glutenfree") {
      return "Gluten Free Protein Bars";
    }
    if (filter === "vegan") {
      return "Vegan Protein Bars";
    }
    if (filter === "all") {
      return "All Protein Bars";
    }
    return "All Protein Bars";
  }
}

const ItemShowcaseMobile = (props) => {
  return (
    <ListRoundedSquare
      to={props.link}
      onClick={() => {
        window.scroll(0, 0);
        trackEvent("Home-All", "Clicked " + props.name);
      }}
      color={props.color}
    >
      <FrontRoundedSquare>
        <ListContentDiv>
          <RankImage>
            {props.loaded ? (
              <ListImageDiv>
                <ListImage src={props.image} />
              </ListImageDiv>
            ) : (
              <ItemLoadingDiv>
                <CircularProgress color="inherit" />
              </ItemLoadingDiv>
            )}
          </RankImage>
          <ListTitleDiv>
            <ListTitle>{props.name}</ListTitle>
            <ListSubtitle>
              {props.loaded ? props.count + " products" : "Loading products"}
            </ListSubtitle>
          </ListTitleDiv>
          <ListItemBar />
        </ListContentDiv>
      </FrontRoundedSquare>
    </ListRoundedSquare>
  );
};

const Home = (props) => {
  const [message, setMessage] = useState("");
  const [prevMessage, setPrevMessage] = useState("");
  const [response, setResponse] = useState(null);
  const [category, setCategory] = useState(null);
  const [ingredient1, setIngredient1] = useState(null);
  const [ingredient2, setIngredient2] = useState(null);
  const [ingredient3, setIngredient3] = useState(null);
  const [ingredient4, setIngredient4] = useState(null);
  const [ingredient5, setIngredient5] = useState(null);
  const [ingredient6, setIngredient6] = useState(null);
  const [ingredient7, setIngredient7] = useState(null);
  const [ingredient8, setIngredient8] = useState(null);
  const [ingredient9, setIngredient9] = useState(null);
  const [ingredient10, setIngredient10] = useState(null);
  const [exclude1, setExclude1] = useState(null);
  const [exclude2, setExclude2] = useState(null);
  const [exclude3, setExclude3] = useState(null);
  const [exclude4, setExclude4] = useState(null);
  const [exclude5, setExclude5] = useState(null);
  const [filter, setFilter] = useState(null);
  const [rank, setRank] = useState(null);
  const [patent, setPatent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [maintenance, setMaintenance] = useState(false);
  const [logoFade, setLogoFade] = useState(true);
  const [loginScreen, setLoginScreen] = useState(false);
  const [exampleLoading, setExampleLoading] = useState(true);
  const [thumbnailData, setThumbnailData] = useState([]);
  const characterLimit = 75;
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupIngredients, setPopupIngredients] = useState(null);
  const [popupIngredientList, setPopupIngredientList] = useState(null);
  const [isAdPopupOpen, setAdPopupOpen] = useState(false);

  const closePopup = () => setPopupOpen(false);
  const closeAdPopup = () => setAdPopupOpen(false);

  const handlePopUp = (ingredients, ingredientList) => {
    setPopupOpen(true);
    setPopupIngredients(ingredients);
    setPopupIngredientList(ingredientList);
  };

  useEffect(() => {
    if (!props.userCountry) return;

    const fetchExampleData = (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.ok) {
            const data = await response.json();
            resolve(data);
          } else {
            reject(new Error(`HTTP error! status: ${response.status}`));
          }
        } catch (error) {
          reject(error);
        }
      });
    };

    const fetchAllExamples = (userCountry) => {
      const fetchThumbnails = fetchExampleData(
        "/api/thumbnails?region=" + userCountry
      ); //

      setExampleLoading(true);

      Promise.all([fetchThumbnails])
        .then(([dataThumbnails]) => {
          setThumbnailData(dataThumbnails);
          setExampleLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchAllExamples(props.userCountry);
  }, [props.userCountry]);

  const suggestionsList = [
    "pump preworkouts",
    "low calorie protein bar",
    "best value protein",
    "affordable creatine",
    "high stim pre",
    "high value electrolytes",
    "clear protein",
    "mass gainer",
    "whey isolate protein",
    "preworkouts with beta alanine and caffeine",
    "all glycerol preworkouts",
    "vegan protein powder",
    "stim free preworkouts",
    "hcl creatine",
  ];

  const getCategoryData = (loading, items, category) => {
    let index;

    if (loading || !Array.isArray(items) || items.length <= index) {
      return {
        count: "",
        image: "",
      };
    } else {
      index = items.findIndex((i) => i.category === category);
    }

    return {
      count: items[index].count,
      image: items[index].imglink,
    };
  };

  const betaData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "betaalanine"
  );
  const citrullineData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "citrulline"
  );
  const creatineData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "creatine"
  );
  const massData = getCategoryData(exampleLoading, thumbnailData, "massgainer");
  const electrolyteData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "electrolyte"
  );
  const preworkoutData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "preworkout"
  );
  const proteinpowderData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "proteinpowder"
  );
  const proteinbarData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "proteinbar"
  );

  const Categories = [
    {
      name: "Pre-Workout",
      subtitle: "Click to see current Podium.",
      count: preworkoutData.count,
      image: preworkoutData.image,
      link: "/preworkout/examples",
      key: "preworkout",
    },
    {
      name: "Protein Powder",
      subtitle: "Click to see current Podium.",
      count: proteinpowderData.count,
      image: proteinpowderData.image,
      link: "/proteinpowder/examples",
      key: "proteinpowder",
    },
    {
      name: "Electrolytes",
      subtitle: "Click to see current Podium.",
      count: electrolyteData.count,
      image: electrolyteData.image,
      link: "/electrolyte/examples",
      key: "electrolyte",
    },
    {
      name: "Protein Bars",
      subtitle: "Click to see current Podium.",
      count: proteinbarData.count,
      image: proteinbarData.image,
      link: "/proteinbar/examples",
      key: "proteinbar",
    },
    {
      name: "Mass Gainer",
      subtitle: "Click to see current Podium.",
      count: massData.count,
      image: massData.image,
      link: "/massgainer",
      key: "massgainer",
    },
    {
      name: "Creatine",
      subtitle: "Click to see current Podium.",
      count: creatineData.count,
      image: creatineData.image,
      link: "/creatine/examples",
      key: "creatine",
    },
    {
      name: "Beta Alanine",
      subtitle: "Click to see current Podium.",
      count: betaData.count,
      image: betaData.image,
      link: "/betaalanine/examples",
      key: "betaalanine",
    },
    {
      name: "Citrulline",
      subtitle: "Click to see current Podium.",
      count: citrullineData.count,
      image: citrullineData.image,
      link: "/citrulline/examples",
      key: "citrulline",
    },
  ];

  const ref = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const startDragging = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - ref.current.offsetLeft);
    setScrollLeft(ref.current.scrollLeft);
  };

  const stopDragging = (event) => {
    setIsDragging(false);
  };

  const whileDragging = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - ref.current.offsetLeft;
    const walk = (x - startX) * 3; // the *3 is the scroll speed factor
    ref.current.scrollLeft = scrollLeft - walk;
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleButtonClick = (item) => {
    if (!isDragging) {
      setMessage(item);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    if (props.isLoggedIn && props.isVerified) {
      setLoginScreen(false);
      handleSubmit();
    } else {
      setLoginScreen(true);
    }
  };

  const handleSubmit = async () => {
    if (message.trim() === "") return;

    try {
      setLoading(true);
      setLogoFade(false);
      // const response = await fetch("/predict/", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ prompt: message }),
      // });

      let response = "";

      if (process.env.REACT_APP_ENV === "production") {
        response = await fetch(
          "/api/search?q=" + message + "&region=" + props.userCountry
        );
      } else {
        response = await fetch(
          "/api/dev-search?q=" + message + "&region=" + props.userCountry,
          {
            headers: {
              "Content-Type": "application/json",
              dev_key: process.env.REACT_APP_DEV_KEY,
            },
          }
        );
      }

      if (process.env.REACT_APP_ENV === "production") {
        const apiResponse = await fetch("/api/prompt", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ prompt: message }),
          credentials: "include",
        });

        if (!apiResponse.ok) {
          throw new Error(`Prompt storing ! status: ${apiResponse.status}`);
        } else {
          console.log("Prompt stored");
        }
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        const data = await response.json();
        const ctg = response.headers.get("category");
        const ing1 = response.headers.get("ingredient1");
        const ing2 = response.headers.get("ingredient2");
        const ing3 = response.headers.get("ingredient3");
        const ing4 = response.headers.get("ingredient4");
        const ing5 = response.headers.get("ingredient5");
        const ing6 = response.headers.get("ingredient6");
        const ing7 = response.headers.get("ingredient7");
        const ing8 = response.headers.get("ingredient8");
        const ing9 = response.headers.get("ingredient9");
        const ing10 = response.headers.get("ingredient10");
        const ex1 = response.headers.get("exclude1");
        const ex2 = response.headers.get("exclude2");
        const ex3 = response.headers.get("exclude3");
        const ex4 = response.headers.get("exclude4");
        const ex5 = response.headers.get("exclude5");
        const reqFilter = response.headers.get("filter");
        const reqRank = response.headers.get("rank");
        const reqPatent = response.headers.get("patent");

        setResponse(data); // Adjust depending on JSON structure
        setCategory(ctg);
        setIngredient1(ing1);
        setIngredient2(ing2);
        setIngredient3(ing3);
        setIngredient4(ing4);
        setIngredient5(ing5);
        setIngredient6(ing6);
        setIngredient7(ing7);
        setIngredient8(ing8);
        setIngredient9(ing9);
        setIngredient10(ing10);
        setExclude1(ex1);
        setExclude2(ex2);
        setExclude3(ex3);
        setExclude4(ex4);
        setExclude5(ex5);
        setFilter(reqFilter);
        setRank(reqRank);
        setPatent(reqPatent);
        setPrevMessage(message);
        setMessage(""); // Clear input after sending
        setLoading(false);
        setMaintenance(false);
      }
    } catch (e) {
      setLoading(false);
      setLogoFade(true);
      setMaintenance(true);
      console.error("There was a problem with the fetch operation:", e);
    }
  };

  const getFilterFunction = (category) => {
    switch (category) {
      case "preworkout":
        return props.setPreFilter;
      case "proteinpowder":
        return props.setProteinFilter;
      case "proteinbar":
        return props.setProteinBarFilter;
      case "electrolyte":
        return props.setElectrolyteFilter;
      case "massgainer":
        return props.setMassFilter;
      case "creatine":
        return props.setCreatineFilter;
      case "citrulline":
        return props.setCitrullineFilter;
      case "betaalanine":
        return props.setBetaFilter;
      default:
        return null;
    }
  };

  const getFilterLinkFunction = (category) => {
    switch (category) {
      case "preworkout":
        return props.setPreFilterLink;
      case "proteinpowder":
        return props.setProteinFilterLink;
      case "proteinbar":
        return props.setProteinBarFilterLink;
      case "electrolyte":
        return props.setElectrolyteFilterLink;
      case "massgainer":
        return props.setMassFilterLink;
      case "creatine":
        return props.setCreatineFilterLink;
      case "citrulline":
        return props.setCitrullineFilterLink;
      case "betaalanine":
        return props.setBetaFilterLink;
      default:
        return null;
    }
  };

  // console.log("Rank: ", rank);
  // console.log("Filter: ", filter);

  return (
    <div>
      <Helmet>
        <title>SUPPHEAD - Supplement Value Rankings</title>
        <meta
          name="description"
          content="Stop falling for supplement marketing. Our website ranks popular
      supplement products by ingredient value to help you find the most
      affordable options. Whether you're looking for a Pre-Workout with
      the highest pump value, a low calorie Protein Powder with the
      highest protein value, or a supplement with the most servings for
      the lowest price, our website provides you with customized rankings
      based on ingredient value. Check out our product rankings above to
      find the highest valued products on the market!"
        />
        <meta
          name="keywords"
          content="supplements, health, fitness, nutrition, supplement reviews, supplement advice, supplement rankings, best protein, best preworkout, best beginner supplements, creatine, pre workout, protein powder, mass gainer, supp, citrulline, beta alanine, stim preworkout"
        />
        <meta property="og:title" content="SUPPHEAD" />
        <meta
          property="og:description"
          content="Stop falling for supplement marketing. Our website ranks popular
      supplement products by ingredient value to help you find the most
      affordable options. Whether you're looking for a Pre-Workout with
      the highest pump value, a low calorie Protein Powder with the
      highest protein value, or a supplement with the most servings for
      the lowest price, our website provides you with customized rankings
      based on ingredient value. Check out our product rankings above to
      find the highest valued products on the market!"
        />
      </Helmet>
      <center>
        <HomeLogo src={mainlogo} logo={false} />
        <Subtitle>Never fall for supplement marketing again.</Subtitle>
        <SearchDiv onSubmit={handleFormSubmit}>
          <Input
            type="text"
            value={message}
            onChange={handleInputChange}
            placeholder="Search SUPPHEAD..."
            maxLength={characterLimit}
          />
          <SearchButton onClick={handleFormSubmit} type="submit">
            {props.isLoggedIn && props.isVerified ? (
              <RightArrowSVG color="black" />
            ) : (
              <LockSVG color="black" />
            )}
          </SearchButton>
        </SearchDiv>
        <CharCountDiv>
          <CharCount
            className={
              message && message.length === characterLimit && "invalid"
            }
          >
            {message ? characterLimit - message.length : characterLimit}{" "}
            {message && characterLimit - message.length === 1
              ? "character"
              : "characters"}{" "}
            left
          </CharCount>
        </CharCountDiv>
        <SuggestionDiv
          ref={ref}
          onMouseDown={startDragging}
          onMouseLeave={stopDragging}
          onMouseUp={stopDragging}
          onMouseMove={whileDragging}
        >
          {suggestionsList.map(
            (item) =>
              item &&
              message !== item &&
              prevMessage !== item && (
                <Suggestion key={item} onClick={() => handleButtonClick(item)}>
                  <SuggestionText>{item}</SuggestionText>
                </Suggestion>
              )
          )}
        </SuggestionDiv>
        {loading && (
          <>
            <ResponseDiv>
              <ResponseTitle>Searching</ResponseTitle>
              <ResponseSubtitle noUnderline={true}>
                Loading products...
              </ResponseSubtitle>
            </ResponseDiv>
            <PodiumDivMobile>
              {/* <CircularProgress color="inherit" /> */}
              <BrandListCompact
                filterRank={1}
                loading={true}
              ></BrandListCompact>
              <BrandListCompact
                filterRank={2}
                loading={true}
              ></BrandListCompact>
              <BrandListCompact
                filterRank={3}
                loading={true}
              ></BrandListCompact>
            </PodiumDivMobile>
            <FilterButtonDiv>
              <ShowMoreButton to={null}>
                <PodiumGlyphImg src={podiumGlyph} />
              </ShowMoreButton>
            </FilterButtonDiv>
            <TabBar />
          </>
        )}
        {!loading && loginScreen && !props.isLoggedIn && (
          <MaintenanceDiv style={{ paddingTop: "0px", paddingBottom: "40px" }}>
            <LockSVG />
            <MaintenanceTitle style={{ paddingTop: "10px" }}>
              Login or Sign Up for Free
            </MaintenanceTitle>
            <LoginSubtitle to={"/account"}>
              Login before using Advanced Search
            </LoginSubtitle>
          </MaintenanceDiv>
        )}
        {!loading && loginScreen && !props.isVerified && props.isLoggedIn && (
          <MaintenanceDiv style={{ paddingTop: "0px", paddingBottom: "40px" }}>
            <LockSVG />
            <MaintenanceTitle style={{ paddingTop: "10px" }}>
              Please Verify your Account
            </MaintenanceTitle>
            <LoginSubtitle to={"/account"}>
              Verify Account before using Advanced Search
            </LoginSubtitle>
          </MaintenanceDiv>
        )}
        {!loading && props.apiStatus === 429 && (
          <MaintenanceDiv>
            <MaintenanceTitle>Too Many Requests</MaintenanceTitle>
            <MaintenanceSubTitle>Please slow down!</MaintenanceSubTitle>
          </MaintenanceDiv>
        )}
        {!loading && props.apiStatus === 404 && (
          <MaintenanceDiv>
            <MaintenanceTitle>SUPPHEAD is offline.</MaintenanceTitle>
            <MaintenanceSubTitle>Please check back later!</MaintenanceSubTitle>
          </MaintenanceDiv>
        )}
        {!loading && maintenance && props.apiStatus === 200 && (
          <MaintenanceDiv>
            <MaintenanceTitle>SUPPHEAD Search Error</MaintenanceTitle>
            <MaintenanceSubTitle>Please try again.</MaintenanceSubTitle>
          </MaintenanceDiv>
        )}
        {!loading && !maintenance && response && response.length === 0 && (
          <ResponseDiv>
            <ResponseTitle>
              <ReactTyped
                strings={["No Search Results"]}
                typeSpeed={30}
                showCursor={false}
              />
            </ResponseTitle>
            <ResponseSubtitle noUnderline={true}>
              Oops! Your search didn't return products. Please try again.
            </ResponseSubtitle>
          </ResponseDiv>
        )}
        {!loading &&
          !maintenance &&
          response &&
          response.length > 0 &&
          category === "massgainer" && (
            <ResponseDiv>
              <ResponseTitle>
                <ReactTyped
                  strings={["All Mass Gainer"]}
                  typeSpeed={30}
                  showCursor={false}
                />
              </ResponseTitle>
              <ResponseSubtitle
                onClick={() =>
                  handlePopUp(["calories", "protein"], massIngredients)
                }
              >
                <SvgWrapper>
                  <IngredientEyeSVG />
                </SvgWrapper>
                Ranked by Highest Calories and Protein value.
              </ResponseSubtitle>
            </ResponseDiv>
          )}
        {!loading &&
          !maintenance &&
          response &&
          response.length > 0 &&
          category === "creatine" && (
            <ResponseDiv>
              <ResponseTitle>
                <ReactTyped
                  strings={[responseMaker(filter, category, patent)]}
                  typeSpeed={30}
                  showCursor={false}
                />
              </ResponseTitle>
              <ResponseSubtitle
                onClick={() =>
                  handlePopUp(
                    filter !== "all" ? [filter] : ["monohydrate"],
                    creatineIngredients
                  )
                }
              >
                <SvgWrapper>
                  <IngredientEyeSVG />
                </SvgWrapper>
                Ranked by Creatine value.
              </ResponseSubtitle>
            </ResponseDiv>
          )}
        {!loading &&
          !maintenance &&
          response &&
          response.length > 0 &&
          category === "preworkout" && (
            <ResponseDiv>
              <ResponseTitle>
                <ReactTyped
                  strings={[
                    titleMakerPW([
                      exclude1,
                      exclude2,
                      exclude3,
                      exclude4,
                      exclude5,
                    ]),
                  ]}
                  typeSpeed={30}
                  showCursor={false}
                />
              </ResponseTitle>
              <ResponseSubtitle
                onClick={() =>
                  handlePopUp(
                    [
                      ingredient1,
                      ingredient2,
                      ingredient3,
                      ingredient4,
                      ingredient5,
                      ingredient6,
                      ingredient7,
                      ingredient8,
                      ingredient9,
                      ingredient10,
                    ],
                    preIngredients
                  )
                }
              >
                <SvgWrapper>
                  <IngredientEyeSVG />
                </SvgWrapper>
                {subMakerPW([
                  ingredient1,
                  ingredient2,
                  ingredient3,
                  ingredient4,
                  ingredient5,
                  ingredient6,
                  ingredient7,
                  ingredient8,
                  ingredient9,
                  ingredient10,
                ])}
              </ResponseSubtitle>
            </ResponseDiv>
          )}
        {!loading &&
          !maintenance &&
          response &&
          response.length > 0 &&
          category === "electrolyte" && (
            <ResponseDiv>
              <ResponseTitle>
                <ReactTyped
                  strings={[responseMaker(filter, category, patent)]}
                  typeSpeed={30}
                  showCursor={false}
                />
              </ResponseTitle>
              <ResponseSubtitle
                onClick={() =>
                  handlePopUp(
                    [
                      ingredient1,
                      ingredient2,
                      ingredient3,
                      ingredient4,
                      ingredient5,
                    ],
                    electrolyteIngredients
                  )
                }
              >
                <SvgWrapper>
                  <IngredientEyeSVG />
                </SvgWrapper>
                {subMakerElectrolytes([
                  ingredient1,
                  ingredient2,
                  ingredient3,
                  ingredient4,
                  ingredient5,
                ])}
              </ResponseSubtitle>
            </ResponseDiv>
          )}
        {!loading &&
          !maintenance &&
          response &&
          response.length > 0 &&
          category === "proteinpowder" && (
            <ResponseDiv>
              <ResponseTitle>
                <ReactTyped
                  strings={[responseMaker(filter, category, patent)]}
                  typeSpeed={30}
                  showCursor={false}
                />
              </ResponseTitle>
              {/* <ResponseSubtitle>{subMakerProtein(rank)}</ResponseSubtitle> */}
              <ResponseSubtitle
                onClick={() =>
                  handlePopUp(
                    rank !== "undefined"
                      ? [
                          "calories",
                          filter === "all" || filter === "glutenfree"
                            ? "protein"
                            : filter,
                        ]
                      : [
                          filter === "all" || filter === "glutenfree"
                            ? "protein"
                            : filter,
                        ],
                    proteinIngredients
                  )
                }
              >
                <SvgWrapper>
                  <IngredientEyeSVG />
                </SvgWrapper>
                {subMakerProtein(rank)}
              </ResponseSubtitle>
            </ResponseDiv>
          )}
        {!loading &&
          !maintenance &&
          response &&
          response.length > 0 &&
          category === "proteinbar" && (
            <ResponseDiv>
              <ResponseTitle>
                <ReactTyped
                  strings={[responseMaker(filter, category, patent)]}
                  typeSpeed={30}
                  showCursor={false}
                />
              </ResponseTitle>
              {/* <ResponseSubtitle>{subMakerProtein(rank)}</ResponseSubtitle> */}
              <ResponseSubtitle
                onClick={() =>
                  handlePopUp(
                    rank !== "undefined"
                      ? [
                          "calories",
                          filter === "all" || filter === "glutenfree"
                            ? "protein"
                            : filter,
                        ]
                      : [
                          filter === "all" || filter === "glutenfree"
                            ? "protein"
                            : filter,
                        ],
                    proteinIngredients
                  )
                }
              >
                <SvgWrapper>
                  <IngredientEyeSVG />
                </SvgWrapper>
                {subMakerProtein(rank)}
              </ResponseSubtitle>
            </ResponseDiv>
          )}
        {!loading && !maintenance && response && response.length > 0 && (
          <>
            <PodiumDivMobile>
              {tieBreaker(response).map(
                (item, index) =>
                  item && (
                    <BrandListCompact
                      item={item}
                      filterRank={item.currentRank}
                      oldRank={item.oldRank}
                      ticker={true}
                      loading={false}
                      ai={true}
                      userCountry={props.userCountry}
                      key={index}
                      filter={response}
                      filterLink={searchPageGen(
                        category,
                        filter,
                        rank,
                        patent,
                        [
                          ingredient1,
                          ingredient2,
                          ingredient3,
                          ingredient4,
                          ingredient5,
                          ingredient6,
                          ingredient7,
                          ingredient8,
                          ingredient9,
                          ingredient10,
                        ],
                        [exclude1, exclude2, exclude3, exclude4, exclude5]
                      )}
                      setFilter={getFilterFunction(category)}
                      setFilterLink={getFilterLinkFunction(category)}
                    />
                  )
              )}
            </PodiumDivMobile>
            <FilterButtonDiv>
              <ShowMoreButton
                to={searchPageGen(
                  category,
                  filter,
                  rank,
                  patent,
                  [
                    ingredient1,
                    ingredient2,
                    ingredient3,
                    ingredient4,
                    ingredient5,
                    ingredient6,
                    ingredient7,
                    ingredient8,
                    ingredient9,
                    ingredient10,
                  ],
                  [exclude1, exclude2, exclude3, exclude4, exclude5]
                )}
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                <PodiumGlyphImg src={podiumGlyph} />
              </ShowMoreButton>
            </FilterButtonDiv>
            <TabBar />
          </>
        )}
        {props.apiStatus === 200 && (
          <>
            <ResponseDiv>
              <ResponseTitle>
                <ReactTyped
                  strings={["All Supplements"]}
                  typeSpeed={30}
                  showCursor={false}
                />
              </ResponseTitle>
              <ResponseSubtitle
                noUnderline={true}
                style={{ cursor: "default" }}
              >
                {"Click below to see product rankings."}
              </ResponseSubtitle>
            </ResponseDiv>
            <PodiumDivMobile>
              {!exampleLoading ? (
                Categories.map((category) => (
                  <ItemShowcaseMobile
                    image={category.image}
                    name={category.name}
                    subtitle={category.subtitle}
                    color="#d4af37"
                    link={category.link}
                    count={category.count}
                    loaded={true}
                    key={category.key}
                  />
                ))
              ) : (
                <>
                  <ItemShowcaseMobile
                    name={"Pre-Workout"}
                    color="#d4af37"
                    loaded={false}
                  />
                  <ItemShowcaseMobile
                    name={"Protein Powder"}
                    color="#d4af37"
                    loaded={false}
                  />
                  <ItemShowcaseMobile
                    name={"Electrolytes"}
                    color="#d4af37"
                    loaded={false}
                  />
                  <ItemShowcaseMobile
                    name={"Protein Bars"}
                    color="#d4af37"
                    loaded={false}
                  />
                  <ItemShowcaseMobile
                    name={"Mass Gainer"}
                    color="#d4af37"
                    loaded={false}
                  />
                  <ItemShowcaseMobile
                    name={"Creatine"}
                    color="#d4af37"
                    loaded={false}
                  />
                  <ItemShowcaseMobile
                    name={"Beta Alanine"}
                    color="#d4af37"
                    loaded={false}
                  />
                  <ItemShowcaseMobile
                    name={"Citrulline"}
                    color="#d4af37"
                    loaded={false}
                  />
                </>
              )}
            </PodiumDivMobile>
            {/* <ResponseDiv style={{paddingTop:"20px"}}>
              <ResponseTitle>
                <ReactTyped
                  strings={["SUPPHEAD Favorites"]}
                  typeSpeed={30}
                  showCursor={false}
                />
              </ResponseTitle>
              <ResponseSubtitle>
                {"Our most popular user product rankings."}
              </ResponseSubtitle>
            </ResponseDiv> */}
            <LastTabBar />
          </>
        )}
      </center>
      <InfoContainer color="white">
        <ExampleDiv>
          <Render src={"https://i.imgur.com/gmASSmrh.png"} />
        </ExampleDiv>
        <ExampleSection>
          <ExampleTitle>How it Works</ExampleTitle>
          <ExampleSubtitle>
            Our website ranks popular supplement products by ingredient value to
            help you find the most affordable options. Whether you're looking
            for a Pre-Workout with the highest pump value, a low calorie Protein
            Powder with the highest protein value, or a supplement with the most
            servings for the lowest price, our website provides you with
            customized rankings based on ingredient value. Check out our product
            rankings above to find the highest valued products on the market!
          </ExampleSubtitle>
        </ExampleSection>
      </InfoContainer>
      <InfoContainer color="#efefef">
        <TransparencySection>
          <TransparencyTitle>Built for Transparency</TransparencyTitle>
          <AboutSubtitle>
            <TransparencyItemListDiv>
              <TransparencyItemDiv>
                <TrophySVG />
                <p>Products Ranked by Ingredient Value</p>
              </TransparencyItemDiv>
              <TransparencyItemDiv>
                <ListSVG />
                <p>Simple UI</p>
              </TransparencyItemDiv>
              <TransparencyItemDiv>
                <CloseSVG color={"#ed1b24"} />
                <p>No Marketing Tricks</p>
              </TransparencyItemDiv>
            </TransparencyItemListDiv>
          </AboutSubtitle>
        </TransparencySection>
        <MacbookDiv>
          <Render src={"https://i.imgur.com/6tNBhZph.png"} />
        </MacbookDiv>
      </InfoContainer>
      <InfoContainer color="white">
        <IphoneDiv>
          <Render src={"https://i.imgur.com/uG2wYkzh.png"} />
        </IphoneDiv>
        <TailoredSection>
          <TailoredTitle>Tailored for You</TailoredTitle>
          <AboutSubtitle>
            <TransparencyItemListDiv>
              <TransparencyItemDiv>
                <DollarSVG />
                <p>Live Prices</p>
              </TransparencyItemDiv>
              <TransparencyItemDiv>
                <FilterSVG />
                <p>Filter Products</p>
              </TransparencyItemDiv>
              <TransparencyItemDiv>
                <CartSVG color={"#d4af37"} />
                <p>Direct Product Links</p>
              </TransparencyItemDiv>
            </TransparencyItemListDiv>
          </AboutSubtitle>
        </TailoredSection>
      </InfoContainer>
      {/* <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={popupIngredients}
        ingredientList={popupIngredientList}
      /> */}
      <AdPopUp canOpen={true} accessories={props.accessories} />
      {/* {!loading && !maintenance && category === "creatine" && (
          <PodiumDivMobile>
            {response &&
              response.map(
                (item, index) =>
                  item && (
                    <ProductListCompact
                      image={item.imglink}
                      brand={item.brand}
                      flavor={item.flavor}
                      flavorCount={item.count}
                      subtitle={item.subtitle}
                      filterRank={index + 1}
                      shop={item.shoplink}
                      price={item.price}
                      servings={item.servings}
                      protein={item.protein}
                      category={item.category}
                      size={item.size}
                      ppd={item.ppd}
                      type={item.type}
                      key={item.brand + " " + item.flavor + " " + item.servings}
                    />
                  )
              )}
          </PodiumDivMobile>
        )} */}
    </div>
  );
};

export default Home;
