import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CartSVG } from "../custom/svg";

const PopupContainer = styled.a`
  all: unset;
  background-color: ${(props) => props.color};
  padding: 20px;
  border-radius: 15px;
  max-width: 500px;
  width: 50%;
  text-align: center;
  position: fixed; /* Changed from relative to fixed */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position to center */
  z-index: 1000;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  @media (max-width: 760px) {
    top: auto; /* Reset top positioning */
    bottom: 1.5%; /* Position at the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust horizontal position */
    width: 85%; /* Set width to 85% on mobile */
    max-width: none; /* Remove max-width constraint */
    border-radius: 15px; /* Rounded top corners */
    // max-height: 50vh; /* Limit height to 50% of viewport */
    overflow-y: hidden; /* Enable scrolling if content overflows */
    padding: 20px; /* Equal padding on all sides */
  }

  @media (min-width: 760px) {
    display: none;
  }
`;
const ShopDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const ShopText = styled.p`
  font-size: 1rem;
  font-family: San Francisco Bold;
  color: ${(props) => props.color};
  margin: 0px;
  // line-height: 40px;
  // position: static;
`;
const CartDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const ShopNowButton = ({ shoplink, color, textColor, price }) => {
  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const bottomPosition = document.body.offsetHeight;

      // Check if the user is at the bottom of the page
      if (scrollPosition >= bottomPosition) {
        setIsAtBottom(true);
      } else {
        setIsAtBottom(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (isAtBottom) {
    return null;
  }

  return (
    <PopupContainer
      href={shoplink}
      color={color}
      target="_blank"
      rel="noreferrer"
    >
      <ShopDiv>
        {/* <ShopText color={textColor}>Shop Now</ShopText> */}
        <ShopText color={textColor}>Shop Now - {price}</ShopText>
        <CartDiv>
          <CartSVG color={textColor} />
        </CartDiv>
      </ShopDiv>
    </PopupContainer>
  );
};

export default ShopNowButton;
