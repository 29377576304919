import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { CloseSVG } from "../custom/svg";
import { filterTieBreaker, preIngredients } from "../custom/functions";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none; /* Prevents touch scrolling on touch devices */
  z-index: 99; // prevents items showing in front
`;

// const PopupContainer = styled.div`
//   background-color: #fff;
//   padding: 20px;
//   // margin-top: 10%;
//   border-radius: 15px;
//   max-width: 500px;
//   width: 50%;
//   text-align: center;
//   position: relative;
//   // overflow-y: auto;
//   // overflow: hidden;

//   @media (max-width: 760px) {
//     width: 85%;
//   }
// `;

const PopupContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  max-width: 500px;
  width: 50%;
  text-align: center;
  position: fixed; /* Changed from relative to fixed */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position to center */
  z-index: 1000;

  @media (max-width: 760px) {
    top: auto; /* Reset top positioning */
    bottom: 1.5%; /* Position at the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust horizontal position */
    width: 85%; /* Set width to 85% on mobile */
    max-width: none; /* Remove max-width constraint */
    border-radius: 15px; /* Rounded top corners */
    // max-height: 50vh; /* Limit height to 50% of viewport */
    overflow-y: hidden; /* Enable scrolling if content overflows */
    padding: 20px; /* Equal padding on all sides */
  }
`;

const TitleWrapper = styled.div`
  position: absolute;
  top: 18px;
  left: 20px;
  padding: 10px 10px;
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 85%;
`;

const Title = styled.p`
  margin: 0;
  font-size: 1.3rem;
  color: black;
  font-family: "San Francisco Bold";

  @media (max-width: 760px) {
    font-size: 1.2rem;
  }
`;

const SubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  gap: 5px;
`;

const Subtitle = styled.p`
  margin: 0;
  font-size: 1rem;
  color: gray;
  font-family: "San Francisco Regular";
  text-align: left;
`;

const SliderContainer = styled.div`
  width: 100%; /* Adjusted to fit within TitleWrapper */
  height: 6px;
  background-color: #efefef;
  border-radius: 3px;
  position: relative;
  margin: 15px 0; /* Adjusted for spacing within TitleWrapper */
`;

const SliderTrack = styled.div`
  position: absolute;
  height: 100%;
  background-color: gray;
  border-radius: 3px;
  left: ${(props) => props.leftPercentage}%;
  width: ${(props) => props.widthPercentage}%;
`;

const SliderHandle = styled.div`
  position: absolute;
  top: 50%;
  left: ${(props) => props.percentage}%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  background-color: gray;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
`;

const CloseButton = styled.button`
  all: unset;
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
  border-radius: 5px;
  border: none;
  padding-top: 3px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    cursor: pointer;
  }
`;
const PopUpIngredientDiv = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 10px;
  padding: 10px;
  padding-top: 125px;
  justify-content: flex-start;

  @media (min-width: 760px) {
    padding-right: 20px;
  }
`;
const PopUpTitle = styled.p`
  display: block;
  height: auto;
  font-family: San Francisco Bold;
  font-size: 1.2rem;
  position: static;
  text-align: left;
  margin: 0px;

  @media (max-width: 760px) {
    font-size: 1.3rem;
  }
`;
const PopUpDefinition = styled.p`
  font-size: 14px;
  font-family: San Francisco Regular;
  color: gray;
  text-align: left;
  padding-bottom: 10px;
  margin: 0px;
`;
const ScrollableContent = styled.div`
  max-height: 60vh; /* Restrict the height of the scrollable content */
  overflow-y: auto; /* Makes the content inside this div scrollable */

  /* Hide scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
const Suggestion = styled.button`
  all: unset;
  display: block;
  background: #efefef;
  border-radius: 7px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  &:active {
    cursor: grabbing;
  }

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;

const SuggestionText = styled.div`
  color: black;
  font-family: San Francisco Bold;
  font-size: 1rem;
  position: static;
  text-align: center;
  margin: 5px;
  white-space: nowrap;

  @media (max-width: 760px) {
    font-size: 0.9rem;
  }
`;

const RangeSlider = ({
  min,
  max,
  selectedIngredient,
  loading,
  rangeData,
  setRangeData,
}) => {
  const selectedRange = rangeData.find(
    (item) => item.ingredient === selectedIngredient
  );
  const [minValue, setMinValue] = useState(
    selectedRange ? selectedRange.min : min
  );
  const [maxValue, setMaxValue] = useState(
    selectedRange ? selectedRange.max : max
  );
  const sliderRef = useRef(null);
  const isDragging = useRef(null); // 'min' or 'max'

  const calculateStepValue = (max) => {
    if (max >= 1000) return 250;
    if (max >= 100) return 25;
    if (max >= 50) return 5;
    if (max >= 10) return 1;
    if (max >= 5) return 0.5;
    if (max >= 1) return 0.1;
    return 0.01; // Default for very small ranges
  };

  // Usage
  const stepValue = calculateStepValue(max);

  const getPercentage = (value) => ((value - min) / (max - min)) * 100;

  const snapToStep = (value) => {
    // Calculate the nearest multiple of stepValue
    const roundedValue =
      Math.round((value - min) / stepValue) * stepValue + min;

    // Ensure the value stays within the min and max range
    return parseFloat(Math.min(Math.max(roundedValue, min), max).toFixed(2));
  };

  const handleDrag = (e) => {
    if (!isDragging.current) return;

    if (e.type.includes("touch")) {
      e.preventDefault(); // Prevent the default behavior for touch events
    }

    const slider = sliderRef.current;
    const rect = slider.getBoundingClientRect();
    const clientX = e.type.includes("touch") ? e.touches[0].clientX : e.clientX;
    const offsetX = clientX - rect.left;
    const newPercentage = Math.min(Math.max(offsetX / rect.width, 0), 1);
    let newValue = newPercentage * (max - min) + min;
    newValue = snapToStep(newValue);

    if (isDragging.current === "min") {
      // Clamp the new minValue to be at most (maxValue - stepValue)
      const clampedMin = Math.min(newValue, maxValue - stepValue);
      setMinValue(clampedMin);
    } else if (isDragging.current === "max") {
      // Clamp the new maxValue to be at least (minValue + stepValue)
      const clampedMax = Math.max(newValue, minValue + stepValue);
      setMaxValue(clampedMax);
    }
  };

  const stopDrag = () => {
    isDragging.current = null;
    document.removeEventListener("mousemove", handleDrag);
    document.removeEventListener("mouseup", stopDrag);
    document.addEventListener("touchmove", handleDrag, { passive: false });
    document.removeEventListener("touchend", stopDrag);
  };

  const startDrag = (handle) => (e) => {
    // if (e.type.includes("touch")) {
    //   e.preventDefault(); // Prevent the default behavior for touch events
    // }

    // e.preventDefault();
    isDragging.current = handle;
    document.addEventListener("mousemove", handleDrag);
    document.addEventListener("mouseup", stopDrag);
    document.addEventListener("touchmove", handleDrag, { passive: false });
    document.addEventListener("touchend", stopDrag);
  };

  const minPercentage = getPercentage(minValue);
  const maxPercentage = getPercentage(maxValue);

  const Ingredient = preIngredients.find(
    (ingredient) => ingredient.value === selectedIngredient
  );

  const updatingRangeData = useRef(false);
  const updatingMinMax = useRef(false);

  // printing for test
  // useEffect(() => {
  //   console.log(rangeData);
  // }, [rangeData]);

  useEffect(() => {
    if (updatingMinMax.current) {
      // Reset the flag and skip updating rangeData to prevent loop
      updatingMinMax.current = false;
      return;
    }

    setRangeData((prevData) => {
      if (maxValue === -Infinity) {
        return [...prevData];
      }

      // First, filter out any ingredients marked with `remove: true`
      const removeCheck = prevData.find((item) => item.remove);
      const filteredData = prevData.filter((item) => !item.remove);

      // Check if an object with `ingredient` equal to `selectedIngredient` exists
      const exists = filteredData.find(
        (item) => item.ingredient === selectedIngredient
      );

      // console.log("Exists: ", exists);

      // Determine values for `min` and `max`
      const hasMinChanged = minValue !== min;
      const hasMaxChanged = maxValue !== max;

      // Ensure maxValue doesn't exceed max
      const adjustedMaxValue = maxValue > max ? max : maxValue;

      // console.log("adjustedMax: ", adjustedMaxValue);

      // Use the adjusted max value in your conditions
      const newMin = hasMinChanged || hasMaxChanged ? minValue : min;
      const newMax = hasMinChanged || hasMaxChanged ? adjustedMaxValue : max;
      const hideValues = !hasMinChanged && !hasMaxChanged;

      // console.log("hasMin: ", hasMinChanged);
      // console.log("hasMax: ", hasMaxChanged);
      // console.log("newMax: ", newMax);
      // console.log("hideValues: ", hideValues);

      if (exists) {
        // Update the object if it exists
        return filteredData.map((item) =>
          item.ingredient === selectedIngredient
            ? { ...item, min: newMin, max: newMax, hide: hideValues } // Update `min` and `max`
            : item
        );
      } // removing ingredient problem here (it's not in the list so it tries to add it back, but adds the next selectedIngredient because that changed causing an infinite loop)
      else {
        // Append the new object if it doesn't exist
        return [
          ...filteredData,
          {
            ingredient: selectedIngredient,
            min: newMin,
            max: newMax,
            hide: hideValues,
          },
        ];
      }
    });
  }, [minValue, maxValue]);

  useEffect(() => {
    const selectedRange = rangeData.find(
      (item) => item.ingredient === selectedIngredient
    );

    let newMinValue;
    if (!selectedRange || selectedRange.min === null || selectedRange.hide) {
      newMinValue = min;
    } else {
      newMinValue = selectedRange.min;
    }

    if (newMinValue !== minValue) {
      updatingMinMax.current = true;
      setMinValue(newMinValue);
    }
  }, [rangeData, min, selectedIngredient]);

  useEffect(() => {
    const selectedRange = rangeData.find(
      (item) => item.ingredient === selectedIngredient
    );

    let newMaxValue;
    if (
      !selectedRange ||
      selectedRange.max === null ||
      selectedRange.max === -Infinity ||
      selectedRange.hide ||
      selectedRange.max > max
    ) {
      newMaxValue = max;
    } else {
      newMaxValue = selectedRange.max;
    }

    if (newMaxValue !== maxValue) {
      updatingMinMax.current = true;
      setMaxValue(newMaxValue);
    }
  }, [rangeData, max, selectedIngredient]);

  return (
    <>
      <SliderContainer ref={sliderRef}>
        <SliderTrack
          leftPercentage={minPercentage}
          widthPercentage={maxPercentage - minPercentage}
        />
        <SliderHandle
          percentage={minPercentage}
          onMouseDown={startDrag("min")}
          onTouchStart={startDrag("min")}
        />
        <SliderHandle
          percentage={maxPercentage}
          onMouseDown={startDrag("max")}
          onTouchStart={startDrag("max")}
        />
      </SliderContainer>
      {!loading && maxValue !== -Infinity && (
        <p style={{ margin: "0px", fontFamily: "San Francisco Regular" }}>
          {Ingredient.label}: {minValue}-{maxValue}
          {Ingredient.unit}
        </p>
      )}
    </>
  );
};

const IngredientRankPopUp = ({
  category,
  isOpen,
  onClose,
  selectedRankIngredients,
  setSelectedRankIngredients,
  ingredientList,
  setIngredientList,
  selectedExcludeIngredients,
  removeActive,
  reorderList,
  getStyle,
  getTextStyle,
  responseLength,
  loading,
  modeToggle,
  advancedMode,
  emptySearch,
  Reset,
  rangeData,
  setRangeData,
  originalMax,
}) => {
  // useEffect(() => {
  //   if (selectedRankIngredients) {
  //     setIngredientList(reorderList(selectedRankIngredients[selectedRankIngredients.length - 1], ingredientList));
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log("Original Max: ", originalMax);
  // }, [originalMax]);

  useEffect(() => {
    if (isOpen) {
      // Prevent background scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable background scrolling
      document.body.style.overflow = "";
    }

    const themeColorMetaTag = document.querySelector(
      'meta[name="theme-color"]'
    );

    if (themeColorMetaTag) {
      if (isOpen) {
        themeColorMetaTag.setAttribute("content", "#7F7F7F"); // Set color when popup is open
      } else {
        themeColorMetaTag.setAttribute("content", "white"); // Reset color when popup is closed
      }
    }

    // Cleanup function to reset the style when the component is unmounted or closed
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleOverlayClick = (event) => {
    onClose(); // Close the popup if clicked outside
  };

  const handlePopupClick = (event) => {
    event.stopPropagation(); // Prevent the click from reaching the overlay
  };

  // add in logic to not remove the item from the list unless it's in the first spot (2nd click)
  const handleRankClick = (item) => {
    setSelectedRankIngredients((prevSelected) => {
      if (selectedExcludeIngredients.includes(item.value)) {
        // Ignore if the item is in the exclude list
        return prevSelected;
      }

      if (prevSelected.includes(item.value)) {
        // Prevent removal if it would result in zero selections
        if (prevSelected.length === 1) {
          return prevSelected;
        }
        // Prevent removal if not selectedIngredient NEED TO FIX
        if (
          item.value !==
          selectedRankIngredients[selectedRankIngredients.length - 1]
        ) {
          // Filter out the current item, then push it to the end
          // PREVENT CALLING DATABASE AGAIN (INEFFICIENT)
          // setIngredientList(reorderList(item, ingredientList));
          setRangeData((prevSelected) => {
            // Find the item to be moved to the back
            const activeItem = prevSelected.find(
              (ingredient) => ingredient.ingredient === item.value
            );

            // Filter out the item from the array
            const filteredData = prevSelected.filter(
              (ingredient) => ingredient.ingredient !== item.value
            );

            // Append the active item to the back of the array, keeping its data intact
            return [...filteredData, activeItem];
          });
          return [
            ...prevSelected.filter((ingredient) => ingredient !== item.value),
            item.value,
          ];
        }

        setIngredientList(
          removeActive(item, ingredientList, selectedRankIngredients.length)
        );

        setRangeData((prevSelected) =>
          prevSelected.filter(
            (ingredient) => ingredient.ingredient !== item.value
          )
        );
        // setMinValue(max);
        return prevSelected.filter((ingredient) => ingredient !== item.value);
      } else if (prevSelected.length < 10) {
        // setIngredientList(reorderList(item, ingredientList));
        setRangeData((prev) => {
          const exists = prev.find((obj) => obj.ingredient === item.value);

          if (!exists) {
            return [
              ...prev,
              { ingredient: item.value, min: null, max: null, hide: true },
            ];
          } else {
            return prev;
          }
        });
        return [...prevSelected, item.value];
      } else {
        return prevSelected;
      }
    });
  };

  return (
    <Overlay onClick={handleOverlayClick}>
      <PopupContainer onClick={handlePopupClick}>
        <TitleWrapper>
          <Title>Rank Ingredients</Title>
          <SubtitleContainer>
            <Subtitle>
              {!emptySearch
                ? !loading
                  ? responseLength
                  : "Loading"
                : "No Search"}{" "}
              Results ‧{" "}
            </Subtitle>
            {category === "preworkout" && (
              <>
                <Subtitle
                  style={{ textDecoration: "underline", cursor: "pointer"}}
                  onClick={modeToggle}
                >
                  {!advancedMode ? "Advanced" : "Normal"}
                </Subtitle>
                <Subtitle> ‧ </Subtitle>
              </>
            )}
            <Subtitle style={{ textDecoration: "underline", cursor: "pointer" }} onClick={Reset}>
              Reset
            </Subtitle>
          </SubtitleContainer>
          <RangeSlider
            min={0}
            max={originalMax}
            selectedIngredient={
              selectedRankIngredients[selectedRankIngredients.length - 1]
            }
            loading={loading}
            rangeData={rangeData}
            setRangeData={setRangeData}
          />
        </TitleWrapper>
        <CloseButton onClick={onClose}>
          <CloseSVG color={"black"} />
        </CloseButton>
        <ScrollableContent>
          {
            <PopUpIngredientDiv>
              {ingredientList.map(
                (item) =>
                  item &&
                  !item.hide && (
                    <Suggestion
                      key={item.value}
                      onClick={() => handleRankClick(item)}
                      style={getStyle(item, selectedRankIngredients)}
                    >
                      <SuggestionText
                        style={getTextStyle(item, selectedRankIngredients)}
                      >
                        {rangeData.some(
                          (range) => range.ingredient === item.value
                        ) && selectedRankIngredients.includes(item.value)
                          ? (() => {
                              const range = rangeData.find(
                                (range) => range.ingredient === item.value
                              );
                              const preIngredient = preIngredients.find(
                                (obj) => obj.value === item.value
                              );

                              if (range?.hide || range.max === -Infinity) {
                                return item.label; // If hide is true, just return the label
                              }

                              // Show min-max range only if both are not null
                              const min =
                                range?.min !== null ? range.min : null;
                              const max =
                                range?.max !== null ? range.max : null;
                              const unit = preIngredient
                                ? preIngredient.unit
                                : "";

                              const rangeDisplay =
                                min !== null && max !== null
                                  ? ` (${min}-${max}${unit})`
                                  : "";

                              return `${item.label}${rangeDisplay}`;
                            })()
                          : item.label}
                      </SuggestionText>
                    </Suggestion>
                  )
              )}

              {/* {ingredients.map((ingredient, index) => {
                if (!ingredient || ingredient === "undefined") {
                  return null;
                }

                const tableIngredient = ingredientList.find(
                  (item) => item.value === ingredient
                );
                return (
                  <React.Fragment key={ingredient}>
                    <PopUpTitle>{tableIngredient.label}</PopUpTitle>
                    <PopUpDefinition
                      style={
                        index + 1 === ingredientList.length
                          ? { paddingBottom: "0px" }
                          : {}
                      }
                    >
                      {tableIngredient.definition}
                    </PopUpDefinition>
                  </React.Fragment>
                );
              })} */}
            </PopUpIngredientDiv>
          }
        </ScrollableContent>
      </PopupContainer>
    </Overlay>
  );
};

export default IngredientRankPopUp;
