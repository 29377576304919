import { CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ArticleCard from "../components/ArticleCard";
import { Helmet } from "react-helmet-async";
import { ReactTyped } from "react-typed";
import AdPopUp from "../components/AdPopUp";

const ResponseDiv = styled.div`
  flex-direction: column;
  justify-content: left;
  align-items: left;
  position: static;
  width: 50%;
  max-width: 700px;
  display: flex;
  //   background: black;

  @media (max-width: 760px) {
    width: 100%;
    max-width: 700px;
    // background: gray;
  }
`;

const ResponseTitle = styled.h1`
  all: unset;
  display: block;
  height: auto;
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  position: static;
  text-align: left;
  padding-left: 40px;
  margin: 0px;

  @media (max-width: 760px) {
    font-size: 1.3rem;
    padding-left: 30px;
    padding-right: 20px;
  }
`;
const ResponseSubtitle = styled.h4`
  all: unset;
  font-size: 1rem;
  font-family: San Francisco Regular;
  color: gray;
  text-align: left;
  padding-left: 40px;
  padding-right: 30px;
  margin: 0px;
  padding-top: 5px;
  padding-bottom: 20px;
  white-space: pre-line;
  cursor: default;

  @media (max-width: 760px) {
    padding-left: 30px;
    padding-right: 20px;
    font-size: 0.9rem;
  }

  //   @media (min-width: 760px) {
  //     &:hover {
  //       text-decoration: ${(props) =>
    props.noUnderline ? "none" : "underline"};
  //     }
  //   }

  //   &:active {
  //     text-decoration: underline;
  //   }
`;
const ArticleDiv = styled.div`
  width: 95%;
  position: static;
  padding-bottom: 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media (min-width: 760px) {
    width: 70%;
    max-width: 700px;
  }
`;

const ArticlePage = (props) => {
  const [articleData, setArticleData] = useState([]);
  const [articleLoading, setArticleLoading] = useState(true);

  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        const response = await fetch(`/api/article`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          setArticleData(data); // Assuming you're setting the first article in the data array
          setArticleLoading(false);
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchArticleData();
  }, []);

  return (
    <center>
      <Helmet>
        <title>Articles</title>
        <meta
          name="description"
          content="A complete list of our supplement articles."
        />
        <meta property="og:title" content="Articles" />
        <meta
          property="og:description"
          content="A complete list of our supplement articles."
        />
      </Helmet>
      <ResponseDiv>
        <ResponseTitle style={{ paddingTop: "20px" }}>
          <ReactTyped
            strings={["All Articles"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle>
          A complete list of our supplement articles.
        </ResponseSubtitle>
      </ResponseDiv>
      <ArticleDiv>
        {!articleLoading ? (
          articleData.map((item, index) => (
            <ArticleCard key={item.id} article={item} />
          ))
        ) : (
          <center style={{ paddingTop: "100px", paddingBottom: "200px" }}>
            <CircularProgress color="inherit" />
          </center>
        )}
      </ArticleDiv>
      <AdPopUp
        canOpen={true}
        accessories={props.accessories}
      />
    </center>
  );
};

export default ArticlePage;
