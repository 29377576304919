import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { IngredientEyeSVG, LeftArrowSVG } from "../custom/svg";
import PopUp from "../components/PopUp";
import {
  calculateReadingTime,
  extractSearchParams,
  preIngredients,
  proteinBarIngredients,
  proteinIngredients,
  searchPageGen,
  subMakerProtein,
  subMakerPW,
  tieBreaker,
  titleMakerProtein,
  titleMakerProteinBar,
  titleMakerPW,
} from "../custom/functions";
import BrandListCompact from "../components/BrandListCompact";
import podiumGlyph from "../images/podiumglyph.png";
import { CircularProgress } from "@mui/material";

const StyledLink = styled(Link)`
  text-decoration: none; /* Remove underline */
  color: inherit; /* Ensure text color is inherited */
`;

const ResponseDiv = styled.div`
  flex-direction: column;
  justify-content: left;
  align-items: left;
  position: static;
  width: 50%;
  max-width: 700px;
  display: flex;
  //   background: black;

  @media (max-width: 760px) {
    width: 100%;
    max-width: 700px;
    // background: gray;
  }
`;

const ResponseTitle = styled.h1`
  all: unset;
  display: block;
  height: auto;
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  position: static;
  text-align: left;
  padding-left: 40px;
  margin: 0px;

  @media (max-width: 760px) {
    font-size: 1.3rem;
    padding-left: 30px;
    padding-right: 20px;
  }
`;
const ResponseSubtitle = styled.h4`
  all: unset;
  font-size: 1rem;
  font-family: San Francisco Regular;
  color: gray;
  text-align: left;
  padding-left: 40px;
  padding-right: 30px;
  margin: 0px;
  padding-top: 5px;
  padding-bottom: 20px;
  white-space: pre-line;
  cursor: pointer;

  @media (max-width: 760px) {
    padding-left: 30px;
    padding-right: 20px;
    font-size: 0.9rem;
  }

  @media (min-width: 760px) {
    &:hover {
      text-decoration: ${(props) => (props.noUnderline ? "none" : "underline")};
    }
  }

  //   &:active {
  //     text-decoration: underline;
  //   }
`;
const ReadTime = styled.p`
  all: unset;
  font-size: 0.8rem;
  font-family: San Francisco Regular;
  color: gray;
  text-align: left;
  padding-left: 40px;
  padding-right: 30px;
  margin: 0px;
  padding-top: 5px;
  padding-bottom: 20px;
  white-space: pre-line;
  cursor: default;

  @media (max-width: 760px) {
    padding-left: 30px;
    font-size: 0.7rem;
  }

  //   @media (min-width: 760px) {
  //     &:hover {
  //       text-decoration: ${(props) =>
    props.noUnderline ? "none" : "underline"};
  //     }
  //   }

  //   &:active {
  //     text-decoration: underline;
  //   }
`;
const ParagraphDiv = styled.div`
  flex-direction: column;
  justify-content: left;
  align-items: left;
  position: static;
  width: 50%;
  max-width: 700px;
  display: flex;
  //   background: black;

  @media (max-width: 760px) {
    width: 100%;
    max-width: 700px;
    // background: gray;
  }
`;
const Paragraph = styled.p`
  all: unset;
  font-size: 1rem;
  font-family: San Francisco Regular;
  color: gray;
  text-align: left;
  padding-left: 40px;
  padding-right: 30px;
  margin: 0px;
  //   padding-top: 5px;
  padding-bottom: 30px;
  white-space: pre-line;
  cursor: default;

  @media (max-width: 760px) {
    padding-left: 30px;
    font-size: 0.9rem;
  }
`;
const BackDiv = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  line-height: 14px;
  font-family: San Francisco Regular;
  padding-bottom: 10px;
  // background: gray;

  @media (max-width: 760px) {
    width: 90%;
    height: 50px;
    line-height: 12px;
    padding-bottom: 0px;
  }
`;
const BackButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 5px;

  &:active {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
  }
`;
const BackText = styled.p`
  font-size: 14px;
  color: black;
  // margin: 0px;

  @media (max-width: 760px) {
    font-size: 12px;
  }
`;
const SvgWrapper = styled.span`
  margin-right: 5px;
  margin-bottom: 3px;
  display: inline-flex;
  vertical-align: middle;

  @media (max-width: 760px) {
    margin-bottom: 2px;
  }
`;
const PodiumDivMobile = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: static;
  width: 70%;
  max-width: 700px;
  gap: 10px;
  display: flex;

  // padding-bottom: 40px;

  @media (max-width: 760px) {
    display: flex;
    width: 95%;
    // padding-bottom: 30px;
  }
`;
const FilterButtonDiv = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: static;
  padding-top: 20px;

  @media (max-width: 900px) {
    width: 90%;
  }
`;
const ShowMoreButton = styled(Link)`
  text-decoration: none;
  width: 80px;
  height: 40px;
  background-color: #efefef;
  border-radius: 10px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0px 0px 5px black;
  }

  @media (max-width: 760px) {
    &:hover {
      box-shadow: none;
    }
  }
`;
const PodiumGlyphImg = styled.img`
  width: 40px;
  position: static;
`;

const Article = (props) => {
  const { id } = useParams();

  const [articleLoading, setArticleLoading] = useState(true);
  const [exampleLoading, setExampleLoading] = useState(true);
  const [exampleOne, setExampleOne] = useState([]);
  const [articleData, setArticleData] = useState(null);
  const [apiValues, setApiValues] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupIngredients, setPopupIngredients] = useState(null);
  const [popupIngredientList, setPopupIngredientList] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [exclude, setExclude] = useState([]);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (!props.userCountry) return;

    const fetchArticleData = async () => {
      try {
        const response = await fetch(`/api/article?id=` + id, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          setArticleData(data[0]); // Assuming you're setting the first article in the data array
          setArticleLoading(false);
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchArticleData();
  }, [props.userCountry]);

  // Second useEffect: Fetch the product data once articleData is set
  useEffect(() => {
    if (!articleData || !props.userCountry) return;

    const values = extractSearchParams(articleData.apicall);

    setApiValues(values);

    // setting preworkout ingredients from api call
    if (articleData.category === "preworkout") {
      setIngredients(
        Object.keys(values)
          .filter((key) => key.includes("ingredient"))
          .map((key) => values[key])
      );
      setExclude(
        Object.keys(values)
          .filter((key) => key.includes("exclude"))
          .map((key) => values[key])
      );
    }

    // console.log(extractSearchParams(articleData.apicall));

    const fetchProductData = async () => {
      try {
        const response = await fetch(
          articleData.apicall.includes("?")
            ? articleData.apicall + `&region=` + props.userCountry
            : articleData.apicall + `?region=` + props.userCountry,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setExampleOne(data);
          setExampleLoading(false);
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      } catch (error) {
        console.log(error);
      }
    };

    setExampleLoading(true);
    fetchProductData();
  }, [articleData, props.userCountry]);

  return (
    <center>
      {!articleLoading && (
        <Helmet>
          <title>{articleData.title}</title>
          <meta name="description" content={articleData.content} />
          <meta property="og:title" content={articleData.title} />
          <meta property="og:description" content={articleData.subtitle} />
        </Helmet>
      )}
      <BackDiv>
        <StyledLink to={"/article"} method="get">
          <BackButton type="submit">
            <LeftArrowSVG />
            <BackText>All Articles</BackText>
          </BackButton>
        </StyledLink>
        {/* <BackText style={{color:"gray"}}>{rank} Place</BackText> */}
      </BackDiv>
      {!articleLoading ? (
        <>
          <ResponseDiv>
            <ResponseTitle>{articleData.title}</ResponseTitle>
            <ResponseSubtitle
              style={{ paddingBottom: "10px", cursor: "default" }}
              noUnderline={true}
            >
              {articleData.subtitle}
            </ResponseSubtitle>
            <ReadTime>
              {calculateReadingTime(articleData.content)} MIN READ
            </ReadTime>
          </ResponseDiv>
          <ParagraphDiv>
            <Paragraph>{articleData.content}</Paragraph>
          </ParagraphDiv>
          <ResponseDiv>
            <ResponseTitle>
              {articleData.category === "proteinpowder" &&
                titleMakerProtein(apiValues.filter)}
              {articleData.category === "proteinbar" &&
                titleMakerProteinBar(apiValues.filter)}
              {articleData.category === "preworkout" && titleMakerPW(exclude)}
            </ResponseTitle>
            <ResponseSubtitle onClick={openPopup}>
              <SvgWrapper>
                <IngredientEyeSVG />
              </SvgWrapper>
              {(articleData.category === "proteinpowder" ||
                articleData.category === "proteinbar") &&
                subMakerProtein(apiValues.rank)}
              {articleData.category === "preworkout" && subMakerPW(ingredients)}
            </ResponseSubtitle>
          </ResponseDiv>
        </>
      ) : (
        <center style={{ paddingTop: "100px", paddingBottom: "200px" }}>
          <CircularProgress color="inherit" />
        </center>
      )}
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleOne).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleOne}
                  filterLink={searchPageGen(
                    articleData && articleData.category,
                    apiValues.filter,
                    apiValues.rank,
                    null,
                    ingredients,
                    exclude
                  )}
                  setFilter={
                    articleData.category === "proteinpowder"
                      ? props.setProteinFilter
                      : articleData.category === "preworkout"
                      ? props.setPreFilter
                      : articleData.category === "proteinbar"
                      ? props.setProteinBarFilter
                      : /* other filters or default value */
                        null
                  }
                  setFilterLink={
                    articleData.category === "proteinpowder"
                      ? props.setProteinFilterLink
                      : articleData.category === "preworkout"
                      ? props.setPreFilterLink
                      : articleData.category === "proteinbar"
                      ? props.setProteinBarFilterLink
                      : /* other links or default value */
                        null
                  }
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          style={{ marginBottom: "25px" }}
          to={searchPageGen(
            articleData && articleData.category,
            apiValues.filter,
            apiValues.rank,
            null,
            ingredients,
            exclude
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} />
        </ShowMoreButton>
      </FilterButtonDiv>
      {apiValues && articleData && (
        <PopUp
          isOpen={isPopupOpen}
          onClose={closePopup}
          ingredients={
            apiValues.rank
              ? ["calories", apiValues.filter || "protein"]
              : ingredients.length > 0
              ? ingredients
              : [apiValues.filter || "protein"]
          }
          ingredientList={
            articleData.category === "proteinpowder"
              ? proteinIngredients
              : articleData.category === "preworkout"
              ? preIngredients
              : articleData.category === "proteinbar"
              ? proteinBarIngredients
              : []
          }
        />
      )}
    </center>
  );
};

export default Article;
