import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { CloseSVG } from "../custom/svg";
import { filterTieBreaker, preIngredients } from "../custom/functions";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none; /* Prevents touch scrolling on touch devices */
  z-index: 99; // prevents items showing in front
`;

// const PopupContainer = styled.div`
//   background-color: #fff;
//   padding: 20px;
//   // margin-top: 10%;
//   border-radius: 15px;
//   max-width: 500px;
//   width: 50%;
//   text-align: center;
//   position: relative;
//   // overflow-y: auto;
//   // overflow: hidden;

//   @media (max-width: 760px) {
//     width: 85%;
//   }
// `;

const PopupContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  max-width: 500px;
  width: 50%;
  text-align: center;
  position: fixed; /* Changed from relative to fixed */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position to center */
  z-index: 1000;

  @media (max-width: 760px) {
    top: auto; /* Reset top positioning */
    bottom: 1.5%; /* Position at the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust horizontal position */
    width: 85%; /* Set width to 85% on mobile */
    max-width: none; /* Remove max-width constraint */
    border-radius: 15px; /* Rounded top corners */
    // max-height: 50vh; /* Limit height to 50% of viewport */
    overflow-y: hidden; /* Enable scrolling if content overflows */
    padding: 20px; /* Equal padding on all sides */
  }
`;

const TitleWrapper = styled.div`
  position: absolute;
  top: 18px;
  left: 20px;
  padding: 10px 10px;
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 85%;
`;

const Title = styled.p`
  margin: 0;
  font-size: 1.3rem;
  color: black;
  font-family: "San Francisco Bold";

  @media (max-width: 760px) {
    font-size: 1.2rem;
  }
`;

const SubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  gap: 5px;
`;

const Subtitle = styled.p`
  margin: 0;
  font-size: 1rem;
  color: gray;
  font-family: "San Francisco Regular";
  text-align: left;
`;

const SliderContainer = styled.div`
  width: 100%; /* Adjusted to fit within TitleWrapper */
  height: 6px;
  background-color: #efefef;
  border-radius: 3px;
  position: relative;
  margin: 15px 0; /* Adjusted for spacing within TitleWrapper */
`;

const SliderTrack = styled.div`
  position: absolute;
  height: 100%;
  background-color: gray;
  border-radius: 3px;
  left: ${(props) => props.leftPercentage}%;
  width: ${(props) => props.widthPercentage}%;
`;

const SliderHandle = styled.div`
  position: absolute;
  top: 50%;
  left: ${(props) => props.percentage}%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  background-color: gray;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
`;

const CloseButton = styled.button`
  all: unset;
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
  border-radius: 5px;
  border: none;
  padding-top: 3px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    cursor: pointer;
  }
`;
const PopUpIngredientDiv = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 10px;
  padding: 10px;
  padding-top: 70px;
  justify-content: flex-start;

  @media (min-width: 760px) {
    padding-right: 20px;
  }
`;
const PopUpTitle = styled.p`
  display: block;
  height: auto;
  font-family: San Francisco Bold;
  font-size: 1.2rem;
  position: static;
  text-align: left;
  margin: 0px;

  @media (max-width: 760px) {
    font-size: 1.3rem;
  }
`;
const PopUpDefinition = styled.p`
  font-size: 14px;
  font-family: San Francisco Regular;
  color: gray;
  text-align: left;
  padding-bottom: 10px;
  margin: 0px;
`;
const ScrollableContent = styled.div`
  max-height: 60vh; /* Restrict the height of the scrollable content */
  overflow-y: auto; /* Makes the content inside this div scrollable */

  /* Hide scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
const Suggestion = styled.button`
  all: unset;
  display: block;
  background: #efefef;
  border-radius: 7px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  &:active {
    cursor: grabbing;
  }

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;

const SuggestionText = styled.div`
  color: black;
  font-family: San Francisco Bold;
  font-size: 1rem;
  position: static;
  text-align: center;
  margin: 5px;
  white-space: nowrap;

  @media (max-width: 760px) {
    font-size: 0.9rem;
  }
`;

const TypePopUp = ({
  isOpen,
  onClose,
  selectedRankIngredients,
  selectedExcludeIngredients,
  setSelectedExcludeIngredients,
  excludeList,
  setExcludeList,
  removeActive,
  reorderList,
  getStyle,
  getTextStyle,
  responseLength,
  loading,
  modeToggle,
  advancedMode,
  emptySearch,
  Reset,
}) => {
  useEffect(() => {
    if (isOpen) {
      // Prevent background scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable background scrolling
      document.body.style.overflow = "";
    }

    const themeColorMetaTag = document.querySelector(
      'meta[name="theme-color"]'
    );

    if (themeColorMetaTag) {
      if (isOpen) {
        themeColorMetaTag.setAttribute("content", "#7F7F7F"); // Set color when popup is open
      } else {
        themeColorMetaTag.setAttribute("content", "white"); // Reset color when popup is closed
      }
    }

    // Cleanup function to reset the style when the component is unmounted or closed
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  // go through and add the ingredients that aren't in range
  // should I be setting their range to null if no change? seems easier and if there range is null i wont show the values on the buttons
  // useEffect(() => {
  //   setRangeData((prevData) => {
  //     if (maxValue === -Infinity) {
  //       return [...prevData];
  //     }

  //     // Check if an object with `ingredient` equal to `selectedIngredient` exists
  //     const exists = prevData.some(
  //       (item) => item.ingredient === selectedIngredient
  //     );

  //     if (exists) {
  //       // Update the object if it exists
  //       return prevData.map((item) =>
  //         item.ingredient === selectedIngredient
  //           ? { ...item, min: minValue, max: maxValue } // Update `min` and `max`
  //           : item
  //       );
  //     } else {
  //       // Append the new object if it doesn't exist
  //       return [
  //         ...prevData,
  //         { ingredient: selectedIngredient, min: null, max: null },
  //       ];
  //     }
  //   });
  // }, [selectedRankIngredients]);

  if (!isOpen) return null;

  const handleOverlayClick = (event) => {
    onClose(); // Close the popup if clicked outside
  };

  const handlePopupClick = (event) => {
    event.stopPropagation(); // Prevent the click from reaching the overlay
  };

  // add in logic to not remove the item from the list unless it's in the first spot (2nd click)
  const handleExcludeClick = (item) => {
    setSelectedExcludeIngredients((prevSelected) => {
        if (prevSelected === item.value) {
            // setTypeList(
            //   removeActive(item, typeList, 1)
            // );
            return null;
          } else if (prevSelected !== item.value) {
            // setTypeList(reorderList(item, typeList));
            return item.value;
          } else {
            return prevSelected;
          }
    });
  };

  const handleRangeChange = (minValue, maxValue) => {
    // console.log("Selected Range:", minValue, "-", maxValue);
  };

  return (
    <Overlay onClick={handleOverlayClick}>
      <PopupContainer onClick={handlePopupClick}>
        <TitleWrapper>
          <Title>Product Type</Title>
          <SubtitleContainer>
            <Subtitle>
              {!emptySearch
                ? !loading
                  ? responseLength
                  : "Loading"
                : "No Search"}{" "}
              Results ‧{" "}
            </Subtitle>
            {/* <Subtitle
              style={{ textDecoration: "underline" }}
              onClick={modeToggle}
            >
              {!advancedMode ? "Advanced" : "Normal"}
            </Subtitle>
            <Subtitle> ‧ </Subtitle> */}
            <Subtitle style={{ textDecoration: "underline", cursor: "pointer" }} onClick={Reset}>
              Reset
            </Subtitle>
          </SubtitleContainer>
          {/* <RangeSlider
            min={0}
            max={originalMax}
            onChange={handleRangeChange}
            selectedIngredient={
              selectedRankIngredients[selectedRankIngredients.length - 1]
            }
            loading={loading}
            items={items}
            setItems={setItems}
            rangeData={rangeData}
            setRangeData={setRangeData}
          /> */}
        </TitleWrapper>
        <CloseButton onClick={onClose}>
          <CloseSVG color={"black"} />
        </CloseButton>
        <ScrollableContent>
          {
            <PopUpIngredientDiv>
              {excludeList.map(
                (item) =>
                  item &&
                  !item.hide && (
                    <Suggestion
                      key={item.value}
                      onClick={() => handleExcludeClick(item)}
                      style={getStyle(item, selectedExcludeIngredients)}
                    >
                      <SuggestionText
                        style={getTextStyle(item, selectedExcludeIngredients)}
                      >
                        {item.label}
                      </SuggestionText>
                    </Suggestion>
                  )
              )}
              {/* {ingredients.map((ingredient, index) => {
                if (!ingredient || ingredient === "undefined") {
                  return null;
                }

                const tableIngredient = ingredientList.find(
                  (item) => item.value === ingredient
                );
                return (
                  <React.Fragment key={ingredient}>
                    <PopUpTitle>{tableIngredient.label}</PopUpTitle>
                    <PopUpDefinition
                      style={
                        index + 1 === ingredientList.length
                          ? { paddingBottom: "0px" }
                          : {}
                      }
                    >
                      {tableIngredient.definition}
                    </PopUpDefinition>
                  </React.Fragment>
                );
              })} */}
            </PopUpIngredientDiv>
          }
        </ScrollableContent>
      </PopupContainer>
    </Overlay>
  );
};

export default TypePopUp;
