import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useLocation, useSearchParams } from "react-router-dom";
import { BounceEffect, LiveIndicator } from "../custom/animations";
import BrandListCompact from "../components/BrandListCompact";
import ReactPaginate from "react-paginate";
import { trackEvent } from "../custom/analytics";
import { ChartSVG, IngredientEyeSVG } from "../custom/svg";
import {
  Regions,
  electrolyteIngredients,
  filterTieBreaker,
  itemFilter,
  subMakerElectrolytes,
} from "../custom/functions";
import PopUp from "../components/PopUp";
import IngredientRankPopUp from "../components/IngredientRankPopUp";
import TypePopUp from "../components/TypePopUp";
import BrandListPlaceholder from "../components/BrandListPlaceholder";
import { Helmet } from "react-helmet-async";
import AdPopUp from "../components/AdPopUp";

// CSS FOLD
const NewPodiumDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: static;
  padding: 0px 0px 30px 0px;
  width: 1500px;
  animation: ${(props) => props.filtered && BounceEffect} 0.25s ease 1;

  @media (max-width: 760px) {
    display: none;
  }
`;
const PodiumDivMobile = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: static;
  width: 70%;
  max-width: 700px;
  gap: 10px;
  display: flex;
  animation: ${(props) => props.filtered && BounceEffect} 0.25s ease 1;

  padding-top: 20px;
  padding-bottom: 20px;

  @media (max-width: 760px) {
    display: flex;
    width: 95%;
  }
`;
const SvgWrapper = styled.span`
  margin-right: 5px;
  margin-bottom: 3px;
  display: inline-flex;
  vertical-align: middle;

  @media (max-width: 760px) {
    margin-bottom: 2px;
  }
`;
const FilterSubtitle = styled.p`
  color: gray;
  font-size: 1rem;
  font-family: San Francisco Regular;
  padding: 0px 20px;
  max-width: 60%;
  cursor: pointer;

  @media (max-width: 760px) {
    font-size: 0.9rem;
    max-width: 90%;
  }

  @media (min-width: 760px) {
    &:hover {
      text-decoration: underline;
    }
  }

  &:active {
    text-decoration: underline;
  }
`;
const Title = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  margin: 20px 0px 0px 0px;
  width: 90%;

  @media (max-width: 900px) {
    font-size: 1.3rem;
  }
`;
const FilterDiv = styled.div`
  width: 50rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: static;
  padding: 0px 0px 20px 0px;

  @media (max-width: 900px) {
    width: 90%;
    flex-wrap: wrap;
  }
`;
const FilterButtonDiv = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: static;
  padding: 0px 0px 20px 0px;

  @media (max-width: 900px) {
    width: 90%;
  }
`;
const FilterButtons = styled.button`
  all: unset;
  display: block;
  text-decoration: none;
  font-family: San Francisco Bold;
  width 120px;
  height: 40px;
  background-color: #efefef;
  border-radius: 10px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  margin: 0px 0px 20px 0px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;
const ListDiv = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: static;
  width: 70%;
  max-width: 700px;
  gap: 10px;
  display: flex;
  padding: 0px 0px 20px 0px;

  @media (max-width: 760px) {
    display: flex;
    width: 95%;
  }
`;
const ListShopText = styled.p`
  font-size: 1rem;
  color: black;
  margin: auto;
  line-height: 40px;
  position: relative;
`;
const StyledReactPaginate = styled(ReactPaginate).attrs({
  //activeClassName: 'active'
})`
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  justify-content: center;
  list-style: none;
  padding: 0px;

  li {
    padding-left: 10px;
    padding-right: 10px;
  }

  li.selected {
    text-decoration: underline;
  }
`;

const SuggestionDiv = styled.div`
  display: flex;
  flex-direction: row;
  // justify-content: center;
  gap: 10px;
  // margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  // margin-bottom: 15px;
  cursor: grab;
  user-select: none; // Prevent text selection during drag

  &:active {
    cursor: grabbing;
  }

  overflow-x: auto; // Changed from 'scroll' to 'auto'

  @media (min-width: 760px) {
    width: 70%;
    max-width: 700px;
  }

  @media (min-width: 760px) {
    justify-content: center;
  }

  // Hiding the scrollbar for WebKit browsers
  &::-webkit-scrollbar {
    display: none;
  }

  // For Firefox 64+
  scrollbar-width: none; // Hides the scrollbar in Firefox

  // For Internet Explorer and Edge
  -ms-overflow-style: none; // Hides the scrollbar in IE and Edge
`;
const Suggestion = styled.button`
  all: unset;
  display: block;
  background: #efefef;
  border-radius: 7px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  &:active {
    cursor: grabbing;
  }

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;

const SuggestionText = styled.div`
  color: black;
  font-family: San Francisco Bold;
  font-size: 1rem;
  position: static;
  text-align: center;
  margin: 5px;
  white-space: nowrap;

  @media (max-width: 760px) {
    font-size: 0.9rem;
  }
`;
const BackDiv = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  line-height: 14px;
  font-family: San Francisco Regular;
  padding-bottom: 10px;
  // background: gray;

  @media (max-width: 760px) {
    width: 90%;
    height: 50px;
    line-height: 12px;
    padding-bottom: 0px;
  }
`;
const BackButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 3px;

  &:active {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
  }
`;
const BackText = styled.p`
  font-size: 14px;
  color: black;
  // margin: 0px;

  @media (max-width: 760px) {
    font-size: 12px;
  }
`;
const RankInfo = styled.div`
  display: flex;
  align-items: left;
  width: 95%;
  padding-left: 10px;

  @media (max-width: 760px) {
    width: 90%;
  }
`;
const RankText = styled.p`
  font-size: 14px;
  color: gray;
  margin-top: 0px;
  text-align: left;

  @media (max-width: 760px) {
    font-size: 12px;
  }
`;

const Type = [
  {
    value: "powder",
    label: "Powder",
  },
  {
    value: "capsule",
    label: "Capsules",
  },
  {
    value: "packet",
    label: "Packets",
  },
  {
    value: "tablet",
    label: "Tablets",
  },
];

const Ingredients = [
  { value: "calcium", label: "Calcium" },
  { value: "chloride", label: "Chloride" },
  { value: "magnesium", label: "Magnesium" },
  { value: "potassium", label: "Potassium" },
  { value: "sodium", label: "Sodium" },
];

const SelectCategories = [
  { value: "rank", label: "Rank Ingredients" },
  { value: "type", label: "Type" },
];

function responseMaker(type) {
  if (type === "powder") {
    return "Electrolyte Powder";
  }
  if (type === "capsule") {
    return "Electrolyte Capsules";
  }
  if (type === "tablet") {
    return "Electrolyte Tablets";
  }
  if (type === "packet") {
    return "Electrolyte Packets";
  }

  return "All Electrolytes";
}

function newapiHandler(selectedRank, filter, userCountry) {
  let link = "/api/electrolyte?";
  const params = new URLSearchParams();

  selectedRank.forEach((ingredient, index) => {
    const value = Ingredients.find((i) => i.value === ingredient)?.value;
    if (value) {
      params.append(`ingredient${index + 1}`, value);
    }
  });

  if (filter) {
    params.append("filter", filter);
  }

  params.append("region", userCountry);

  link += params.toString();
  // console.log(link);
  return link;
}

const ElectrolyteFilter = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [advancedMode, setAdvancedMode] = useState(false);
  const [infoActive, setInfoActive] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [regionSelected, setRegion] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isRankPopupOpen, setRankPopupOpen] = useState(false);
  const [isTypePopupOpen, setTypePopupOpen] = useState(false);
  const [originalMax, setOriginalMax] = useState(-Infinity);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  const openRankPopup = () => setRankPopupOpen(true);
  const closeRankPopup = () => setRankPopupOpen(false);

  const openTypePopup = () => setTypePopupOpen(true);
  const closeTypePopup = () => setTypePopupOpen(false);

  const getParamArray = (paramPrefix, rangePrefix) => {
    const ingredientsArray = [];
    const rangeDataArray = [];

    for (let i = 1; i <= 10; i++) {
      const ingredient = searchParams.get(`${paramPrefix}${i}`);
      const range = searchParams.get(`${rangePrefix}${i}`);

      if (ingredient) {
        ingredientsArray.push(ingredient.toLowerCase());

        // Parse the range into min and max
        let min = null;
        let max = null;
        if (range) {
          const [parsedMin, parsedMax] = range.split("-");
          min = parsedMin ? parseInt(parsedMin, 10) : null;
          max = parsedMax ? parseInt(parsedMax, 10) : null;
        }

        // Add the correct ingredient and parsed min-max values
        rangeDataArray.push({
          ingredient: ingredient.toLowerCase(), // Correct ingredient name
          min: min,
          max: max,
          hide: min === null || max === null,
        });
      }
    }

    return { ingredientsArray, rangeDataArray };
  };

  const setParamRangeArray = (
    params,
    paramPrefix,
    rangePrefix,
    ingredientsArray,
    rangeDataArray
  ) => {
    // Remove old parameters with the same prefix
    for (let i = 1; i <= 10; i++) {
      params.delete(`${paramPrefix}${i}`);
      params.delete(`${rangePrefix}${i}`);
    }

    // Add new parameters for ingredients and only add range if hide is false
    ingredientsArray.forEach((ingredient, index) => {
      // Always add the ingredient
      params.set(`${paramPrefix}${index + 1}`, ingredient);

      // Find the corresponding range for this ingredient
      const range = rangeDataArray.find((r) => r.ingredient === ingredient);

      // Add range only if hide is false and both min and max are available
      if (range && !range.hide && range.min !== null && range.max !== null) {
        const rangeValue = `${range.min}-${range.max}`;
        params.set(`${rangePrefix}${index + 1}`, rangeValue);
      }
    });
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [originalItems, setOriginalItems] = useState(null);
  const [finalItems, setFinalItems] = useState([]);
  const [emptySearch, setEmptySearch] = useState(false);
  const [resetting, setResetting] = useState(false);

  const [selectedRankIngredients, setSelectedRankIngredients] = useState(() => {
    const rankIngredients = getParamArray(
      "ingredient",
      "ingredientRange"
    ).ingredientsArray;
    return rankIngredients.length > 0
      ? rankIngredients
      : ["potassium", "sodium"];
  });
  const [rangeData, setRangeData] = useState(() => {
    return getParamArray("ingredient", "ingredientRange").rangeDataArray;
  });
  const [selectedFilter, setSelectedFilter] = useState(
    searchParams.get("filter") || null
  );
  const [ingredientList, setIngredientList] = useState([...Ingredients]);

  const reorderList = (activeItem, list) => {
    const filteredList = list.filter((item) => item.value !== activeItem.value);

    return [activeItem, ...filteredList];
  };

  const removeActive = (activeItem, list, selectedLength) => {
    const filteredList = list.filter((item) => item.value !== activeItem.value);
    filteredList.push(activeItem);

    const firstHalf = filteredList.slice(0, selectedLength - 1);
    const secondHalf = filteredList.slice(selectedLength - 1).sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });

    return [...firstHalf, ...secondHalf];
  };

  const handleCategoryClick = (item) => {
    setSelectedCategory((prevSelected) => {
      if (item.value === prevSelected) {
        return null;
      } else if (item.value === "rank") {
        openRankPopup();
      } else if (item.value === "type") {
        openTypePopup();
      } else {
        return item.value;
      }
    });
  };

  const handleRegionClick = (item) => {
    // props.setUserCountry(item.value);
    setRegion((prevSelected) => {
      if (prevSelected === item.value) {
        // setTypeList(
        //   removeActive(item, typeList, 1)
        // );
        return prevSelected;
      } else if (prevSelected !== item.value) {
        // setTypeList(reorderList(item, typeList));
        return item.value;
      } else {
        return prevSelected;
      }
    });
  };

  const reorderSelectedItems = (selectedItems, fullList) => {
    // Filter the list to get the selected items first
    const selectedItemsList = fullList.filter((item) =>
      selectedItems.includes(item.value)
    );
    // Filter the list to get the non-selected items
    const nonSelectedItemsList = fullList.filter(
      (item) => !selectedItems.includes(item.value)
    );
    // Concatenate the selected items at the front and non-selected items at the end
    return [...selectedItemsList, ...nonSelectedItemsList];
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        // apiHandler(ingredientOne, ingredientTwo, excludeOne, excludeTwo)
        newapiHandler(selectedRankIngredients, selectedFilter, regionSelected)
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      trackEvent("Filter", "Filtered: Electrolytes", "");
      if (result.length > 0) {
        setEmptySearch(false);
        // setMobilePodium([...result.slice(0, 3)]);
        // setList([...result.slice(3, result.length)]);

        const params = new URLSearchParams();
        setParamRangeArray(
          params,
          "ingredient",
          "ingredientRange",
          selectedRankIngredients,
          rangeData
        );
        if (selectedFilter) params.append("filter", selectedFilter);
        if (regionSelected) params.append("region", regionSelected);

        setSearchParams(params, { replace: true });
        setOriginalItems(result);
        props.setElectrolyteFilter([...result]);
        props.setElectrolyteFilterLink("/electrolyte?" + params.toString());

        setIngredientList(
          reorderSelectedItems(selectedRankIngredients, ingredientList)
        );

        if (resetting) {
          setRangeData([
            { ingredient: "potassium", min: null, max: null, hide: true },
            { ingredient: "sodium", min: null, max: null, hide: true },
          ]);
          setResetting(false);
        }

        // console.log(mergedItems);
        setFinalItems(filterTieBreaker(result));
        setLoading(false);
      } else {
        setEmptySearch(true);
      }
    } catch (error) {
      setError(error); // Update the state with the error
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [props.userCountry]);

  useEffect(() => {
    if (regionSelected === null && props.userCountry) {
      setRegion(searchParams.get("region") || props.userCountry);
    }
  }, [props.userCountry, searchParams]);

  useEffect(() => {
    if (!regionSelected) return;
    // window.scroll(0, 0);
    fetchData();
    // console.log(selectedRankIngredients);
  }, [selectedRankIngredients, selectedFilter, regionSelected]);

  const getStyle = (item, list) => {
    return list.includes(item.value)
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyle = (item, list) => {
    return list.includes(item.value)
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const getCategoryStyle = (item, selected) => {
    return item.value === selected
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getCategoryTextStyle = (item, selected) => {
    return item.value === selected
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const getStyleType = (item, list) => {
    return item.value === list
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyleType = (item, list) => {
    return item.value === list
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const infoToggle = () => {
    setInfoActive(!infoActive);
  };

  const Reset = () => {
    setSelectedRankIngredients(["potassium", "sodium"]);
    setSelectedFilter(null);
    setIngredientList([...Ingredients]);
    // setRangeData([{ ingredient: "caffeine", min: 0, max: originalMax }]);
    setResetting(true);
  };

  useEffect(() => {
    if (
      !originalItems ||
      originalItems.length === 0 ||
      !selectedRankIngredients ||
      selectedRankIngredients.length === 0
    ) {
      // console.log("Data not available yet.");
      return; // Exit the effect early
    }

    let newMax = -Infinity;

    originalItems.forEach((item) => {
      const baseValue = parseFloat(
        item[selectedRankIngredients[selectedRankIngredients.length - 1]]
      );

      // console.log(item[selectedRankIngredients[selectedRankIngredients.length - 1]]);

      const ingredientTotal = item.twoscoop ? 2 * baseValue : baseValue;

      if (ingredientTotal > newMax) {
        newMax = ingredientTotal;
      }
    });

    // console.log("Setting originalMax to:", newMax);
    setOriginalMax(newMax);
  }, [originalItems, selectedRankIngredients, selectedFilter]);

  // filters data based on rangedata
  useEffect(() => {
    // console.log("Filter Effect: ", originalItems);
    const params = new URLSearchParams();
    setParamRangeArray(
      params,
      "ingredient",
      "ingredientRange",
      selectedRankIngredients,
      rangeData
    );
    if (selectedFilter) params.append("filter", selectedFilter);
    if (regionSelected) params.append("region", regionSelected);
    setSearchParams(params, { replace: true });

    const filteredItems = itemFilter(originalItems, rangeData);

    setFinalItems(() => {
      if (!originalItems) return []; // Handle the case where originalItems is undefined

      return filteredItems;
    });
    // Set empty search to true if no items are filtered
    if (originalItems) {
      setEmptySearch(filteredItems.length === 0);
    }

    props.setElectrolyteFilter([...filteredItems]);
    props.setElectrolyteFilterLink("/electrolyte?" + params.toString());
  }, [originalItems, rangeData, selectedRankIngredients, selectedFilter]);

  return (
    <center style={{ overflowX: "hidden" }}>
      <Helmet>
        <title>Electrolyte Filter</title>
        <meta
          name="description"
          content="Discover the best value Electrolytes with our ingredient value ranking system. Whether you want a high potassium or low sodium hydration formula, our rankings highlight top Electrolytes based on ingredient value."
        />
        <meta
          name="keywords"
          content="best electrolytes, electrolyte rankings, hydration supplement, electrolyte value, high sodium electrolytes, low sodium electrolytes, top hydration formula, effective electrolyte ingredients, affordable electrolyte packets, workout supplements, electrolyte comparison, ingredient-based electrolyte powder, ingredient value electrolytes, electrolyte ingredient definitions"
        />
        <meta property="og:title" content="Electrolyte Filter" />
        <meta
          property="og:description"
          content="Discover the best value Electrolytes with our ingredient value ranking system. Whether you want a high potassium or low sodium hydration formula, our rankings highlight top Electrolytes based on ingredient value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton onClick={infoToggle}>
          <ChartSVG />
          <BackText>Ranking Info {!infoActive ? "+" : "-"}</BackText>
        </BackButton>
        <LiveIndicator />
        {/* </StyledLink>
          <BackText style={{color:"gray"}}>{rank} Place</BackText> */}
      </BackDiv>
      {infoActive && (
        <RankInfo>
          <RankText>
            Products are ranked by Total Selected Ingredient Value. <br />
            Formula: (Total Servings * Ingredient Serving) / Price
          </RankText>
        </RankInfo>
      )}
      <PodiumDivMobile style={{ paddingTop: "0px" }}>
        {!loading ? (
          <>
            {finalItems.slice(0, 3).map((item, index) => {
              if (!item) return null;
              return (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  userCountry={regionSelected}
                  ticker={true}
                  ai={true}
                  key={index}
                  loading={false}
                />
              );
            })}
            {finalItems.length < 3 &&
              Array.from({ length: 3 - finalItems.length }).map((_, i) => (
                <BrandListPlaceholder key={`placeholder-${i}`} index={i} />
              ))}
          </>
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <Title>
        {!emptySearch ? responseMaker(selectedFilter) : "No Search Results"}
      </Title>
      <FilterSubtitle onClick={!emptySearch ? openPopup : null}>
        {!emptySearch && (
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
        )}
        {!emptySearch
          ? subMakerElectrolytes(selectedRankIngredients, rangeData)
          : "Oops! Your search didn't return products. Please try again."}
      </FilterSubtitle>
      <SuggestionDiv
        style={{ justifyContent: "center", paddingBottom: "10px" }}
      >
        {/* <SuggestionText>Type:</SuggestionText> */}
        {Regions.map(
          (item) =>
            item && (
              <Suggestion
                key={item.value}
                onClick={() => handleRegionClick(item)}
                style={getStyle(item, regionSelected || "")}
              >
                <SuggestionText
                  style={getTextStyle(item, regionSelected || "")}
                >
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <SuggestionDiv
        style={{
          paddingTop: "5px",
          marginBottom: "30px",
          justifyContent: "center",
        }}
      >
        {SelectCategories.map(
          (item) =>
            item &&
            !item.hide && (
              <Suggestion
                key={item.value}
                onClick={() => handleCategoryClick(item)}
                style={getCategoryStyle(item, selectedCategory)}
              >
                <SuggestionText
                  style={getCategoryTextStyle(item, selectedCategory)}
                >
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <ListDiv>
        {!loading ? (
          finalItems.slice(3).map((item, index) => {
            if (!item) return null;
            return (
              <BrandListCompact
                item={item}
                filterRank={item.currentRank}
                oldRank={item.oldRank}
                userCountry={regionSelected}
                ticker={true}
                ai={true}
                key={index}
                loading={false}
              />
            );
          })
        ) : (
          <>
            <BrandListCompact filterRank={4} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={5} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={6} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={7} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={8} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={9} loading={true}></BrandListCompact>
          </>
        )}
      </ListDiv>
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={selectedRankIngredients}
        ingredientList={electrolyteIngredients}
      />
      <IngredientRankPopUp
        categoyr="electrolyte"
        isOpen={isRankPopupOpen}
        onClose={closeRankPopup}
        selectedRankIngredients={selectedRankIngredients}
        setSelectedRankIngredients={setSelectedRankIngredients}
        ingredientList={ingredientList}
        setIngredientList={setIngredientList}
        selectedExcludeIngredients={[]}
        removeActive={removeActive}
        reorderList={reorderList}
        getStyle={getStyle}
        getTextStyle={getTextStyle}
        responseLength={finalItems.length}
        loading={loading}
        emptySearch={emptySearch}
        Reset={Reset}
        rangeData={rangeData}
        setRangeData={setRangeData}
        originalMax={originalMax}
        originalItems={originalItems}
      />
      <TypePopUp
        isOpen={isTypePopupOpen}
        onClose={closeTypePopup}
        selectedRankIngredients={[]}
        setSelectedRankIngredients={[]}
        selectedExcludeIngredients={selectedFilter}
        setSelectedExcludeIngredients={setSelectedFilter}
        excludeList={Type}
        removeActive={removeActive}
        getStyle={getStyleType}
        getTextStyle={getTextStyleType}
        responseLength={finalItems.length}
        loading={loading}
        emptySearch={emptySearch}
        Reset={Reset}
      />
      <AdPopUp
        canOpen={!isPopupOpen && !isRankPopupOpen && !isTypePopupOpen}
        accessories={props.accessories}
      />
      {/* <StyledReactPaginate
          pageCount={totalPages}
          onPageChange={handlePageChange}
          previousLabel={"Prev"}
        /> */}
    </center>
  );
};

export default ElectrolyteFilter;
